import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const ContentContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    min-height: 370px;
    justify-content: center;
`;

export const DescriptionContainer = styled.div`
    max-width: 398px;
    margin-bottom: 2.375rem;
`;

export const IconsContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
`;

const Icon = styled(FontAwesomeIcon)`
    font-size: 3.5rem;
    color: ${props => props.colors};
`;

export const CheckIcon = styled(Icon)`
    color: ${props => props.theme.colors.status.laSalleGreen};
`;

export const LoadingIcon = styled(Icon)`
    color: ${props => props.theme.colors.primary.richElectricBlue};
`;

export const ErrorIcon = styled(Icon)`
    color: ${props => props.theme.colors.status.maximumRed};
`;

export const Footer = styled.div`
    background-color: ${props => props.theme.colors.background.whiteSmoke};
    padding: 0.75rem 0.5rem;
    display: flex;
    justify-content: flex-end;
    gap: 1.5rem;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
`;
