import styled, { css } from "styled-components";

export const Container = styled.div`
    min-height: 100vh;
    background-color: ${props => props.theme.colors.background.whiteSmoke};
`;

export const ContentWrapper = styled.div`
    padding: 1.5rem;
`;

export const InfoContainer = styled.div`
    display: grid;
    grid-template-columns: ${props => (props.videoEnabled ? "1fr 1fr 1fr" : "1fr 2fr")};
    grid-gap: 1rem;
    margin-bottom: 2.25rem;

    ${props =>
        props.videoEnabled &&
        css`
            @media (max-width: 1537px) and (min-width: 1241px) {
                grid-template-columns: 1fr 1fr;
            }
        `};

    @media (max-width: 1440px) and (min-width: 1241px) {
        grid-gap: 0.625rem;
        margin-bottom: 1rem;
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 1240px) {
        grid-template-columns: 1fr;
    }
`;

export const SpidCounterContainer = styled.div`
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    align-content: center;
`;

export const InfoBox = styled.div`
    background-color: ${props => props.theme.colors.background.white};
    padding: 1.5rem 1.5rem 2rem;
    height: 100%;

    @media (max-width: 1440px) and (min-width: 1241px) {
        padding: 0.75rem 0.5rem;
    }

    @media (max-width: 1240px) {
        padding: 0.75rem 1rem;
    }

    ${props =>
        props.backgroundImage &&
        css`
            background-image: url(${props => props.backgroundImage});
            background-repeat: no-repeat;
            background-position: bottom 0.5rem right -1.5rem;
            background-size: 28% auto;

            ${props =>
                props.videoEnabled
                    ? css`
                          @media (max-width: 1919px) {
                              background-image: none;
                          }
                      `
                    : css`
                          @media (max-width: 1441px) {
                              background-image: none;
                          }
                      `}
        `}
`;

export const TableContainer = styled.div`
    background-color: ${props => props.theme.colors.background.white};
    padding: 2rem 1.5rem;
`;

export const TableSectionTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
`;

export const TableActionsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const FaqInfoBoxWrapper = styled.div`
    ${props =>
        props.videoEnabled
            ? css`
                  @media (max-width: 1537px) {
                      display: none;
                  }
              `
            : css`
                  @media (max-width: 1240px) {
                      display: none;
                  }
              `}
`;

export const SmallDevicesInfoBoxWrapper = styled.div`
    margin-top: 1rem;
    background-color: ${props => props.theme.colors.background.white};
    padding: 1.5rem 1.5rem 2rem;
    height: 100%;

    @media (max-width: 1439px) and (min-width: 1241px) {
        padding: 0.75rem 0.5rem;
    }

    @media (max-width: 1240px) {
        padding: 0.75rem 1rem;
    }

    ${props =>
        props.backgroundImage &&
        css`
            background-image: url(${props => props.backgroundImage});
            background-repeat: no-repeat;
            background-position: bottom 32px right -24px;
            background-size: 260px;
        `}

    ${props =>
        props.videoEnabled
            ? css`
                  @media (min-width: 1538px) {
                      display: none;
                  }
              `
            : css`
                  @media (min-width: 1241px) {
                      display: none;
                  }
              `}
`;

export const FaqContainer = styled.div`
    margin-top: 2.5rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;

    ${props =>
        props.videoEnabled &&
        css`
            grid-column: 1/3;
        `}
`;

export const StyledA = styled.a`
    color: ${props => props.theme.colors.primary.richElectricBlue};
    text-decoration: none;
    font-size: 1rem;
    max-width: fit-content;

    :hover {
        color: ${props => props.theme.colors.primary.darkImperialBlue};
    }
`;

export const ModalFooterButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1.5rem;
`;
