import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 132px;
    grid-gap: 2.5rem;

    @media (max-width: 1919px) {
        grid-gap: 1rem;
    }
`;

export const LegendContainer = styled.div`
    margin-top: 1rem;
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 0.875rem;
`;

export const LegendSquare = styled.div`
    border-radius: 2px;
    height: 12px;
    width: 12px;
    flex-shrink: 0;
    background-color: ${props => props.backgroundColor};
`;

export const LegendElement = styled.div`
    display: flex;
    gap: 0.375rem;
    align-items: flex-start;
`;

export const LegendText = styled.div`
    margin-top: -4px;
`;

export const ChartContainer = styled.div`
    position: relative;
`;

export const CounterContainer = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const SlotsCounterContainer = styled.div`
    color: ${props => props.theme.colors.text.yankeesBlue};
    font-family: Cairo;
    font-weight: bold;
    display: flex;
    align-items: flex-end;
`;

export const SlotsDescriptionContainer = styled.div`
    @media (max-width: 1919px) {
        display: none;
    }
`;

export const UsedSlotsNumber = styled.div`
    font-size: 50px;
    line-height: 50px;
`;

export const TotalSlotsNumber = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
`;
