import PropTypes from "prop-types";
import { useEffect } from "react";

import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton
} from "@vapor/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { sendSessionLink } from "../../actions/sendSessionLink";
import { useDispatchWithParentApp } from "../../hooks/use-dispatch-with-parent-app";
import { useToast } from "../../providers/toast-provider";

const RaoSpidRequestDialog = ({ email, name, open, onClose, spidRequestId, surname }) => {
    const intl = useIntl();
    const dispatchWithParentApp = useDispatchWithParentApp();
    const { addToast } = useToast();
    const sendSessionLinkStatus = useSelector(state => state.sendSessionLink.status);

    useEffect(() => {
        sendSessionLinkStatus.ended &&
            addToast(
                intl.formatMessage({
                    id: "c-rao-spid-request-dialog.success"
                }),
                "check"
            );
        sendSessionLinkStatus.error &&
            addToast(
                intl.formatMessage({
                    id: "c-rao-spid-request-dialog.error"
                }),
                "error"
            );
    }, [addToast, intl, sendSessionLinkStatus]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="rao-spid-request-dialog-title"
            aria-describedby="rao-spid-request-dialog-description"
        >
            <DialogTitle>
                <FormattedMessage id="c-rao-spid-request-dialog.title" />
                <IconButton color="primary" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </IconButton>
            </DialogTitle>
            <Divider variant="fullWidth" />
            <DialogContent>
                <DialogContentText id="rao-spid-request-dialog-description">
                    <FormattedMessage
                        id="c-rao-spid-request-dialog.description"
                        values={{
                            b: chunk => <b>{chunk}</b>,
                            email: email,
                            name: name,
                            surname: surname
                        }}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    <FormattedMessage id="general.cancel" />
                </Button>
                <Button variant="contained" onClick={() => dispatchWithParentApp(sendSessionLink(spidRequestId))}>
                    <FormattedMessage id="general.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

RaoSpidRequestDialog.propTypes = {
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    spidRequestId: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired
};

export default RaoSpidRequestDialog;
