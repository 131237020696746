import { API_READ_URL } from "../config";
import { getRestCall } from "../utils/rest-api-call";

export const GET_CITIES_START = "GET_CITIES_START";
export const GET_CITIES_ERROR = "GET_CITIES_ERROR";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";

export function getCities(provinceCode) {
    return async (dispatch, parentApp) => {
        dispatch({
            type: GET_CITIES_START
        });

        try {
            const response = await getRestCall(
                `${API_READ_URL}/api/v1/spid/get/cities`,
                {
                    provinceCode: provinceCode
                },
                parentApp
            );

            dispatch({
                type: GET_CITIES_SUCCESS,
                payload: response
            });
        } catch (error) {
            dispatch({
                type: GET_CITIES_ERROR,
                error: error
            });
        }
    };
}
