import React, { createContext, useCallback, useContext, useState } from "react";
import ToastContainer from "../../components/toast-container";

const ToastContext = createContext(null);

const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    let id = 1;

    const addToast = useCallback(
        (message, variant) => {
            setToasts(toasts => [...toasts, { id: id++, message, variant }]);
        },
        [id, setToasts]
    );

    const removeToast = useCallback(
        id => {
            setToasts(toasts => toasts.filter(t => t.id !== id));
        },
        [setToasts]
    );

    return (
        <ToastContext.Provider value={{ addToast, removeToast }}>
            <ToastContainer toasts={toasts} />
            {children}
        </ToastContext.Provider>
    );
};

const useToast = () => {
    const toastHelpers = useContext(ToastContext);

    return toastHelpers;
};

export { ToastContext, useToast };
export default ToastProvider;
