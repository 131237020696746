import React, { useMemo } from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { IntlProvider } from "react-intl";

import { getMessagesFromLocale } from "../i18n";
import SpidList from "./spid-list";

const HandlerRoutes = ({ userLocale = "it" }) => {
    const messages = useMemo(() => getMessagesFromLocale(userLocale), [userLocale]);

    return (
        <IntlProvider key={userLocale} locale={userLocale} messages={messages} timeZoneName="it-IT">
            <BrowserRouter>
                <Routes>
                    <Route element={<SpidList />} path="/" />
                </Routes>
            </BrowserRouter>
        </IntlProvider>
    );
};

export default HandlerRoutes;
