import React from "react";
import PropTypes from "prop-types";

import { Button } from "@ts-digital/vrc";
import { faClock, faSackDollar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

import {
    BuyVideoContainer,
    Link,
    UnstyledLink,
    VideoSlotsRemainingCounter,
    WarningBox,
    WarningIcon,
    WarningMessageContainer
} from "./styled";
import videoImage from "../../../../../../images/video.svg";
import { BodyCopy4, H3 } from "../../../../../vapor-components/typography";
import { ListCircleIcon, ListIcon } from "../../styled";
import { FormattedMessage } from "react-intl";

const VideoChannel = ({ spidSlots }) => {
    const remainingVideoSlots = spidSlots.SPID_VIDEO.totalSlots - spidSlots.SPID_VIDEO.usedSlots;

    return spidSlots.SPID_VIDEO.totalSlots === 0 ? (
        <BuyVideoContainer>
            <img alt="video" src={videoImage} />
            <H3 color="darkImperialBlue">
                <FormattedMessage id="c-new-spid-modal-content.recognition.video.buy-video.title" />
            </H3>
            <BodyCopy4 color="cadet">
                <FormattedMessage id="c-new-spid-modal-content.recognition.video.buy-video.description" />
            </BodyCopy4>
            <UnstyledLink
                href="https://www.teamsystem.com/store/firma-digitale/spid/prezzi/identificazione-video/"
                target="_blank"
                rel="noreferrer"
            >
                <Button kind="secondary">
                    <FormattedMessage id="general.buy-package" />
                </Button>
            </UnstyledLink>
        </BuyVideoContainer>
    ) : (
        <>
            <ul className="fa-ul">
                <li>
                    <span className="fa-li">
                        <ListIcon icon={faClock} />
                    </span>
                    <BodyCopy4 color="yankeesBlue">
                        <FormattedMessage
                            id="c-new-spid-modal-content.recognition.video.time-for-completion"
                            values={{
                                b: chunks => <b>{chunks}</b>
                            }}
                        />
                    </BodyCopy4>
                </li>
                <li>
                    <span className="fa-li">
                        <ListIcon icon={faSackDollar} />
                    </span>
                    <BodyCopy4 color="yankeesBlue">
                        <FormattedMessage
                            id="c-new-spid-modal-content.recognition.video.cost"
                            values={{
                                b: chunks => <b>{chunks}</b>
                            }}
                        />{" "}
                        <VideoSlotsRemainingCounter slots={remainingVideoSlots}>
                            {remainingVideoSlots}
                        </VideoSlotsRemainingCounter>
                        )
                    </BodyCopy4>
                </li>
            </ul>
            {remainingVideoSlots === 0 ? (
                <WarningBox>
                    <WarningIcon>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                    </WarningIcon>
                    <WarningMessageContainer>
                        <BodyCopy4 color="yankeesBlue" weight="bold">
                            <FormattedMessage id="c-new-spid-modal-content.recognition.video.out-of-stock.title" />
                        </BodyCopy4>
                        <BodyCopy4 color="yankeesBlue">
                            <FormattedMessage id="c-new-spid-modal-content.recognition.video.out-of-stock.buy" />
                        </BodyCopy4>
                        <Link>
                            <FormattedMessage id="general.go-to-store" />
                        </Link>
                    </WarningMessageContainer>
                </WarningBox>
            ) : (
                <div>
                    <BodyCopy4 color="yankeesBlue">
                        <FormattedMessage id="c-new-spid-modal-content.recognition.video.info.description" />
                    </BodyCopy4>
                    <ul className="fa-ul">
                        <li>
                            <span className="fa-li">
                                <ListCircleIcon icon={faCircle} />
                            </span>
                            <BodyCopy4 color="yankeesBlue" weight="bold">
                                <FormattedMessage id="c-new-spid-modal-content.recognition.video.info.phone-number-and-email" />
                            </BodyCopy4>
                        </li>
                        <li>
                            <span className="fa-li">
                                <ListCircleIcon icon={faCircle} />
                            </span>
                            <BodyCopy4 color="yankeesBlue">
                                <FormattedMessage
                                    id="c-new-spid-modal-content.recognition.video.info.identity-card"
                                    values={{
                                        b: chunks => <b>{chunks}</b>
                                    }}
                                />
                            </BodyCopy4>
                        </li>
                        <li>
                            <span className="fa-li">
                                <ListCircleIcon icon={faCircle} />
                            </span>
                            <BodyCopy4 color="yankeesBlue" weight="bold">
                                <FormattedMessage id="c-new-spid-modal-content.recognition.video.info.health-insurance-card" />
                            </BodyCopy4>
                        </li>
                        <li>
                            <span className="fa-li">
                                <ListCircleIcon icon={faCircle} />
                            </span>
                            <BodyCopy4 color="yankeesBlue">
                                <FormattedMessage
                                    id="c-new-spid-modal-content.recognition.video.info.connection-and-webcam"
                                    values={{
                                        b: chunks => <b>{chunks}</b>
                                    }}
                                />
                            </BodyCopy4>
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
};

VideoChannel.propTypes = {
    spidSlots: PropTypes.shape({
        SPID: PropTypes.shape({
            totalSlots: PropTypes.number,
            usedSlots: PropTypes.number,
            extraUsageAvailable: PropTypes.bool
        }),
        SPID_VIDEO: PropTypes.shape({
            totalSlots: PropTypes.number,
            usedSlots: PropTypes.number
        })
    })
};

export default VideoChannel;
