import React from "react";
import PropTypes from "prop-types";

import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { FormattedMessage } from "react-intl";

import { BodyCopy4 } from "../vapor-components/typography";
import {
    ChartContainer,
    Container,
    CounterContainer,
    LegendContainer,
    LegendElement,
    LegendSquare,
    LegendText,
    SlotsCounterContainer,
    SlotsDescriptionContainer,
    TotalSlotsNumber,
    UsedSlotsNumber
} from "./styled";

const SpidConsumingChart = ({ spidSlots }) => {
    const data = [
        {
            name: <FormattedMessage id="c-spid-consuming-chart.used" />,
            value: spidSlots.SPID.usedSlots
        },
        {
            name: <FormattedMessage id="c-spid-consuming-chart.available" />,
            value: spidSlots.SPID.totalSlots - spidSlots.SPID.usedSlots
        }
    ];

    const extraData = [
        {
            name: null,
            value: 100
        },
        {
            name: "extradata",
            value: 10
        }
    ];

    const extraUsage =
        spidSlots.SPID.usedSlots > spidSlots.SPID.totalSlots ? spidSlots.SPID.usedSlots - spidSlots.SPID.totalSlots : 0;

    return (
        <Container>
            <ChartContainer>
                <ResponsiveContainer width="99%" maxHeight={164} aspect={2.5}>
                    <PieChart>
                        <Pie
                            data={data}
                            dataKey="value"
                            cx={"50%"}
                            cy={"100%"}
                            startAngle={180}
                            endAngle={0}
                            innerRadius="150%"
                            outerRadius="180%"
                            paddingAngle={1}
                        >
                            <Cell fill="#0d6c80" />
                            <Cell fill="#9fc3cd" />
                        </Pie>
                        {spidSlots.SPID.extraUsageAvailable && spidSlots.SPID.usedSlots > spidSlots.SPID.totalSlots && (
                            <Pie
                                data={extraData}
                                dataKey="value"
                                cx={"50%"}
                                cy={"100%"}
                                startAngle={180}
                                endAngle={0}
                                innerRadius="150%"
                                outerRadius="200%"
                                paddingAngle={1}
                            >
                                <Cell fill="transparent" />
                                <Cell fill="#da2c38" />
                            </Pie>
                        )}
                    </PieChart>
                </ResponsiveContainer>
                <CounterContainer>
                    <SlotsCounterContainer>
                        <UsedSlotsNumber>{spidSlots.SPID.usedSlots}</UsedSlotsNumber>
                        <div>/</div>
                        <TotalSlotsNumber>{spidSlots.SPID.totalSlots}</TotalSlotsNumber>
                    </SlotsCounterContainer>
                    <SlotsDescriptionContainer>
                        <BodyCopy4 color="quickSilver">
                            <FormattedMessage id="c-spid-consuming-chart.slot-description" />
                        </BodyCopy4>
                    </SlotsDescriptionContainer>
                </CounterContainer>
            </ChartContainer>
            <LegendContainer>
                <LegendElement>
                    <LegendSquare backgroundColor="#0d6c80"></LegendSquare>
                    <BodyCopy4>
                        <LegendText>
                            <FormattedMessage id="c-spid-consuming-chart.used" />: {spidSlots.SPID.usedSlots}
                        </LegendText>
                    </BodyCopy4>
                </LegendElement>
                <LegendElement>
                    <LegendSquare backgroundColor="#9fc3cd"></LegendSquare>
                    <BodyCopy4>
                        <LegendText>
                            <FormattedMessage id="c-spid-consuming-chart.available" />:{" "}
                            {spidSlots.SPID.totalSlots - spidSlots.SPID.usedSlots}
                        </LegendText>
                    </BodyCopy4>
                </LegendElement>
                {spidSlots.SPID.extraUsageAvailable && spidSlots.SPID.usedSlots > spidSlots.SPID.totalSlots && (
                    <LegendElement>
                        <LegendSquare backgroundColor="#da2c38"></LegendSquare>
                        <BodyCopy4>
                            <LegendText>
                                <FormattedMessage id="c-spid-consuming-chart.extra-usage" />: {extraUsage}
                            </LegendText>
                        </BodyCopy4>
                    </LegendElement>
                )}
            </LegendContainer>
        </Container>
    );
};

SpidConsumingChart.defaultProps = {
    spidSlots: {
        SPID: {
            totalSlots: 0,
            usedSlots: 0,
            extraUsageAvailable: false
        }
    }
};

SpidConsumingChart.propTypes = {
    spidSlots: PropTypes.shape({
        SPID: PropTypes.shape({
            totalSlots: PropTypes.number,
            usedSlots: PropTypes.number,
            extraUsageAvailable: PropTypes.bool
        })
    })
};

export default SpidConsumingChart;
