import { API_READ_URL } from "../config";
import { getRestCall } from "../utils/rest-api-call";

export const GET_RAO_LIST_START = "GET_RAO_LIST_START";
export const GET_RAO_LIST_ERROR = "GET_RAO_LIST_ERROR";
export const GET_RAO_LIST_SUCCESS = "GET_RAO_LIST_SUCCESS";
export const GET_RAO_LIST_RESET = "GET_RAO_LIST_RESET";

export function getRaoList(name, province, city, page) {
    return async (dispatch, parentApp) => {
        dispatch({
            type: GET_RAO_LIST_START,
            payload: {
                page,
                country: "IT",
                province: province,
                city: city,
                name: name
            }
        });

        try {
            const response = await getRestCall(
                `${API_READ_URL}/api/v1/spid/rao`,
                {
                    country: "IT",
                    province: province,
                    city: city,
                    name: name,
                    page: page,
                    size: 6
                },
                parentApp
            );
            dispatch({
                type: GET_RAO_LIST_SUCCESS,
                payload: {
                    first: response.first,
                    last: response.last,
                    page: response.pageable.pageNumber,
                    size: response.pageable.pageSize,
                    content: response.content,
                    totalElements: response.totalElements,
                    totalPages: response.totalPages
                }
            });
        } catch (error) {
            dispatch({
                type: GET_RAO_LIST_ERROR,
                error: error
            });
        }
    };
}

export function resetGetRaoListStatus() {
    return dispatch => {
        dispatch({
            type: GET_RAO_LIST_RESET
        });
    };
}
