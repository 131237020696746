import React from "react";
import PropTypes from "prop-types";

import { faArrowAltCircleLeft, faArrowAltCircleRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Select } from "@ts-digital/vrc";

import { BodyCopy4 } from "../typography";
import { Container } from "./styled";

const Pagination = ({
    currentPage,
    isFirstPage,
    isLastPage,
    isLoading,
    onChangePage,
    onNextPage,
    onPreviousPage,
    totalPages
}) => {
    const options = [{ description: "1", value: 0 }];
    for (let i = 1; i < totalPages; i++) {
        options.push({
            description: `${i + 1}`,
            value: i
        });
    }

    return (
        <Container>
            <BodyCopy4 color="cadet">Pagina:</BodyCopy4>
            <Select value={currentPage} onChange={onChangePage} options={options} disabled={isLoading} />
            <BodyCopy4 color="cadet">di {totalPages}</BodyCopy4>
            <Button kind="tertiary" size="small" disabled={isFirstPage || isLoading} onClick={onPreviousPage}>
                <FontAwesomeIcon icon={faArrowAltCircleLeft} />
            </Button>
            <Button kind="tertiary" size="small" disabled={isLastPage || isLoading} onClick={onNextPage}>
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
            </Button>
        </Container>
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    isFirstPage: PropTypes.bool.isRequired,
    isLastPage: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    onChangePage: PropTypes.func.isRequired,
    onNextPage: PropTypes.func.isRequired,
    onPreviousPage: PropTypes.func.isRequired,
    totalPages: PropTypes.number.isRequired
};

export default Pagination;
