import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "@ts-digital/vrc";

import { Container, Footer, ModalBody, ModalWrapper, Overlay, TitleContainer } from "./styled";
import { H4 } from "../typography";

const Modal = ({ content, footer, onHide, title, type, width }) => {
    return (
        <>
            <Overlay />
            <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
                <Container width={width}>
                    <TitleContainer type={type}>
                        <H4 color="darkImperialBlue" weight="600">
                            {title}
                        </H4>
                        <Button
                            kind="tertiary"
                            size="small"
                            type="button"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={onHide}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    </TitleContainer>
                    <ModalBody>{content}</ModalBody>
                    {footer && <Footer data-testid="modal-footer">{footer}</Footer>}
                </Container>
            </ModalWrapper>
        </>
    );
};

Modal.defaultProps = {
    type: "primary"
};

Modal.propTypes = {
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    footer: PropTypes.any,
    onHide: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    type: PropTypes.oneOf(["primary", "danger"]),
    width: PropTypes.string
};

export default Modal;
