import styled, { css } from "styled-components";

export const VideoSlotsRemainingCounter = styled.span`
    ${props =>
        props.slots === 0 &&
        css`
            color: ${props => props.theme.colors.accent.rustyRed};
        `}
    font-weight: bold;
`;

export const WarningBox = styled.div`
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 4px;
    border-left-width: 1px;
    border-color: ${props => props.theme.colors.status.crayola};
    border-style: solid;
    background-color: white;
    display: grid;
    grid-template-columns: 64px 1fr 64px;
`;

export const WarningIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: ${props => props.theme.colors.status.crayola};
    height: 64px;
`;

export const WarningMessageContainer = styled.div`
    padding-top: 18px;
    padding-bottom: 18px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const BuyVideoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 378px;
    text-align: center;
    gap: 1rem;
    margin: 1rem auto 1.5rem;
`;

export const Link = styled.a`
    color: ${props => props.theme.colors.primary.richElectricBlue};
    font-size: 1rem;
    font-family: Roboto;
    font-weight: normal;
    text-decoration: underline;
    letter-spacing: 0.44px;
    line-height: 1.5rem;
    cursor: pointer;
    margin-top: 0.5rem;

    :hover {
        color: ${props => props.theme.colors.primary.darkImperialBlue};
    }
`;

export const UnstyledLink = styled.a`
    text-decoration: none;
`;
