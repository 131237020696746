import styled from "styled-components";

export const SessionLinkContainer = styled.div`
    /* TODO utilizzare quando verrà implementata correttamente la funzionalità di copia tramite bottone */
    /* display: flex;
    align-items: flex-end;
    gap: 1rem;

    & > :first-child {
        width: 100%;
    }

    & > :last-child {
        margin-bottom: 8px;
    } */
`;

export const OtherSharingOptionsContainer = styled.div`
    margin-top: 2rem;
`;
