import styled from "styled-components";

export const Overlay = styled.div`
    background: rgba(0, 80, 117, 0.32);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
`;

export const ModalWrapper = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 2;
`;

export const Container = styled.div`
    min-width: 400px;
    max-width: 85vw;
    max-height: 95vh;
    overflow-y: auto;
    width: ${props => props.width};
    background-color: ${props => props.theme.colors.background.white};
    box-shadow: 0px 4px 16px 0px rgba(21, 41, 53, 0.32);

    @media (max-width: 1024px) {
        max-width: 95vw;
    }
`;

export const TitleContainer = styled.div`
    border-bottom: 2px solid
        ${props =>
            props.type === "danger" ? props.theme.colors.accent.rustyRed : props.theme.colors.primary.richElectricBlue};
    padding: 1.5rem 0.75rem 1.5rem 2rem;
    display: grid;
    grid-template-columns: 1fr 3rem;
    align-items: center;
`;

export const ModalBody = styled.div`
    padding: 1.5rem 2rem;
    position: relative;
`;

export const Footer = styled.div`
    background-color: ${props => props.theme.colors.background.whiteSmoke};
    padding: 0.75rem 0.5rem;
`;
