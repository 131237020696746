import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const FiltersContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    border-right: 1px solid ${props => props.theme.colors.primary.columbiaBlue};
    padding-right: 2rem;

    @media (max-width: 768px) {
        gap: 1rem;
        border-right: none;
        padding-right: initial;
        border-bottom: 1px solid ${props => props.theme.colors.primary.columbiaBlue};
        padding-bottom: 2rem;
    }
`;

export const SearchContainer = styled.div`
    display: grid;
    grid-template-columns: minmax(370px, 2fr) minmax(400px, 3fr);
    grid-gap: 3rem;
    margin-top: 1rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-gap: 2rem;
    }
`;

export const RaoListContainer = styled.div`
    padding-right: 1rem;
`;

export const RaoListPageContainer = styled.div`
    min-height: 250px;
`;

export const SearchInputAndButtonContainer = styled.div`
    display: flex;

    & > :first-child {
        min-width: calc(100% - 40px);
    }

    & > button {
        margin-top: 4px;
    }
`;

export const RadioButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-bottom: 1px solid ${props => props.theme.colors.text.quickSilver};
    cursor: pointer;
`;

export const RaoInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const EmptyMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    min-width: 400px;
    text-align: center;
`;

export const SearchIconContainer = styled.div`
    color: ${props => props.theme.colors.background.azureishWhite};
    font-size: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const EmptyListIconsContainer = styled.div`
    position: relative;
`;

export const ListIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.background.azureishWhite};
    font-size: 5rem;
`;

export const TimesIconContainer = styled.div`
    font-size: 1.5rem;
    color: ${props => props.theme.colors.background.white};
    border-radius: 100%;
    height: 3rem;
    width: 3rem;
    background-color: ${props => props.theme.colors.primary.darkImperialBlue};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -1.5rem;
    bottom: -1.5rem;
`;
