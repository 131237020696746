import React, { createContext, useCallback, useState } from "react";

export const CreateNewSpidContext = createContext();

export const CreateNewSpidProvider = ({ children, initialFormData, stepCount }) => {
    const [formData, setFormData] = useState(initialFormData);
    const [currentStep, setCurrentStep] = useState(0);

    const nextStep = useCallback(() => {
        currentStep + 1 < stepCount && setCurrentStep(currentStep + 1);
    }, [currentStep, stepCount]);

    const previousStep = useCallback(() => {
        currentStep - 1 >= 0 && setCurrentStep(currentStep - 1);
    }, [currentStep]);

    return (
        <CreateNewSpidContext.Provider value={{ currentStep, formData, nextStep, previousStep, setFormData }}>
            {children}
        </CreateNewSpidContext.Provider>
    );
};
