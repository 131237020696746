import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import { BodyCopy } from "../vapor-components/typography";

const DeleteSpidRequestModalContent = ({ selectedSpidRequestToDelete }) => {
    return (
        <div>
            <BodyCopy>
                <FormattedMessage
                    id="c-delete-spid-request-modal-content.body"
                    values={{
                        b: chunks => <b>{chunks}</b>,
                        name: selectedSpidRequestToDelete.name,
                        surname: selectedSpidRequestToDelete.surname
                    }}
                />
            </BodyCopy>
        </div>
    );
};

DeleteSpidRequestModalContent.propTypes = {
    selectedSpidRequestToDelete: PropTypes.shape({
        name: PropTypes.string,
        surname: PropTypes.string
    })
};

export default DeleteSpidRequestModalContent;
