import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RadioButton } from "@ts-digital/vrc";
import { FormattedMessage } from "react-intl";

import { ComingSoonContainer, Container, Icon, LabelContainer, RadioButtonContainer } from "./styled";
import { H3 } from "../../../vapor-components/typography";

const SpidTypeSelectorBox = forwardRef(
    ({ disabled, finished, icon, isComingSoon, label, onChange, selected, value, ...muiProps }, ref) => {
        return (
            <Container
                {...muiProps}
                ref={ref}
                tabIndex={0}
                disabled={disabled || isComingSoon || finished}
                selected={selected}
                onClick={() => (disabled || isComingSoon || finished ? {} : onChange(value))}
            >
                <Icon disabled={disabled || isComingSoon || finished}>
                    <FontAwesomeIcon icon={icon} />
                </Icon>
                <LabelContainer>
                    <H3 color={disabled || isComingSoon || finished ? "columbiaBlue" : "darkImperialBlue"}>{label}</H3>
                </LabelContainer>
                <RadioButtonContainer>
                    <RadioButton
                        checked={selected}
                        disabled={disabled || isComingSoon || finished}
                        onChange={() => (disabled || isComingSoon || finished ? {} : onChange(value))}
                        value={value}
                    />
                </RadioButtonContainer>
                {isComingSoon && (
                    <ComingSoonContainer>
                        <FormattedMessage id="c-spid-type-selector-box.coming-soon-disclaimer" />
                    </ComingSoonContainer>
                )}
                {!isComingSoon && finished && (
                    <ComingSoonContainer>
                        <FormattedMessage id="general.finished" />
                    </ComingSoonContainer>
                )}
            </Container>
        );
    }
);

SpidTypeSelectorBox.defaultProps = {
    disabled: false,
    isComingSoon: false
};

SpidTypeSelectorBox.propTypes = {
    disabled: PropTypes.bool,
    finished: PropTypes.bool,
    icon: PropTypes.any.isRequired,
    isComingSoon: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    onChange: PropTypes.func,
    selected: PropTypes.bool.isRequired,
    value: PropTypes.any.isRequired
};

export default SpidTypeSelectorBox;
