import { GET_PROVINCE_ERROR, GET_PROVINCE_START, GET_PROVINCE_SUCCESS } from "../actions/getProvince";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function getProvince(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_PROVINCE_START:
            return {
                ...defaultState,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_PROVINCE_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                payload
            };
        case GET_PROVINCE_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { code: error.code, message: error.message }
                }
            };
        default:
            return state;
    }
}
