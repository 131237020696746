import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { faCircleNotch, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fieldRequired } from "@ts-digital/agyo-validations";
import combineValidators from "@ts-digital/agyo-validations/lib/utils/combineValidators";
import { Input } from "@ts-digital/vrc";
import { Button, Drawer, Grid, HeaderBar, IconButton } from "@vapor/material";
import { EmailValidator } from "commons-validator-js";
import { Field, Form } from "react-final-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { initSpid } from "../../actions/initspid";
import { useDispatchWithParentApp } from "../../hooks/use-dispatch-with-parent-app";
import { useToast } from "../../providers/toast-provider";

const ModifySpidRequestDrawer = ({ formData, onClose, open }) => {
    const intl = useIntl();
    const { addToast } = useToast();
    const dispatchWithParentApp = useDispatchWithParentApp();
    const initSpidStatus = useSelector(state => state.initSpid.status);

    const handleSubmit = values =>
        dispatchWithParentApp(
            initSpid({
                ...formData,
                ...values
            })
        );

    useEffect(() => {
        initSpidStatus.ended &&
            addToast(
                intl.formatMessage({
                    id: "c-modify-spid-request-modal-content.init-spid.success"
                }),
                "check"
            );
        initSpidStatus.error &&
            addToast(
                intl.formatMessage({
                    id: "c-modify-spid-request-modal-content.init-spid.error"
                }),
                "error"
            );
    }, [addToast, intl, initSpidStatus]);

    const normalizeTaxId = value => {
        return value.toUpperCase();
    };

    const normalizeEmail = value => {
        return value.toLowerCase();
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose} hideBackdrop>
            <HeaderBar
                divider
                size="small"
                rightItems={[
                    <IconButton onClick={onClose} color="primary">
                        <FontAwesomeIcon icon={faTimes} />
                    </IconButton>
                ]}
                title={<FormattedMessage id="v-spid-list.modify-spid-request-modal-title" />}
            />
            <Form onSubmit={handleSubmit} initialValues={formData}>
                {props => (
                    <Grid container sx={{ p: 6 }} spacing={3} data-testid="modify-spid-request-container">
                        <Grid item xs={7}>
                            <Field name="taxId" parse={normalizeTaxId}>
                                {({ input, meta, props }) => (
                                    <Input
                                        {...input}
                                        {...props}
                                        label={intl.formatMessage({
                                            id: "c-new-spid-modal-content.owner-data.field.taxid.label"
                                        })}
                                        placeholder={intl.formatMessage({
                                            id: "c-new-spid-modal-content.owner-data.field.taxid.placeholder"
                                        })}
                                        required={true}
                                        disabled={true}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={7}>
                            <Field name="name" validate={fieldRequired("Campo obbligatorio")}>
                                {({ input, meta, props }) => (
                                    <Input
                                        {...input}
                                        {...props}
                                        label={intl.formatMessage({
                                            id: "c-new-spid-modal-content.owner-data.field.name.label"
                                        })}
                                        placeholder={intl.formatMessage({
                                            id: "c-new-spid-modal-content.owner-data.field.name.placeholder"
                                        })}
                                        required={true}
                                        error={meta.touched === true && meta.error !== undefined}
                                        errorMessage={meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={7}>
                            <Field name="surname" validate={fieldRequired("Campo obbligatorio")}>
                                {({ input, meta, props }) => (
                                    <Input
                                        {...input}
                                        {...props}
                                        label={intl.formatMessage({
                                            id: "c-new-spid-modal-content.owner-data.field.surname.label"
                                        })}
                                        placeholder={intl.formatMessage({
                                            id: "c-new-spid-modal-content.owner-data.field.surname.placeholder"
                                        })}
                                        required={true}
                                        error={meta.touched === true && meta.error !== undefined}
                                        errorMessage={meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={7} sx={{ marginBottom: "100px" }}>
                            <Field
                                name="email"
                                validate={combineValidators(fieldRequired("Campo obbligatorio"), value =>
                                    new EmailValidator().isValid(value) ? null : "Email non valida"
                                )}
                                parse={normalizeEmail}
                            >
                                {({ input, meta, props }) => (
                                    <Input
                                        {...input}
                                        {...props}
                                        label={intl.formatMessage({
                                            id: "c-new-spid-modal-content.owner-data.field.email.label"
                                        })}
                                        placeholder={intl.formatMessage({
                                            id: "c-new-spid-modal-content.owner-data.field.email.placeholder"
                                        })}
                                        required={true}
                                        error={meta.touched === true && meta.error !== undefined}
                                        errorMessage={meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined" onClick={onClose} sx={{ width: "100%" }}>
                                <FormattedMessage id="general.cancel" />
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                onClick={props.handleSubmit}
                                disabled={initSpidStatus.started}
                                sx={{ width: "100%" }}
                            >
                                {initSpidStatus.started ? (
                                    <FontAwesomeIcon className="fa-spin" icon={faCircleNotch} />
                                ) : (
                                    <FormattedMessage id="general.continue" />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Form>
        </Drawer>
    );
};

ModifySpidRequestDrawer.propTypes = {
    setIsModifySpidRequestDrawerOpen: PropTypes.func.isRequired
};

export default ModifySpidRequestDrawer;
