import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "@ts-digital/vrc";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { initSpid } from "../../../../actions/initspid";
import { useDispatchWithParentApp } from "../../../../hooks/use-dispatch-with-parent-app";
import { BodyCopy4, H3 } from "../../../vapor-components/typography";
import { CreateNewSpidContext } from "../../../../providers/create-new-spid-provider";
import {
    CheckIcon,
    ContentContainer,
    DescriptionContainer,
    ErrorIcon,
    Footer,
    IconsContainer,
    LoadingIcon
} from "./styled";

const Feedback = ({ onClose }) => {
    const { formData } = useContext(CreateNewSpidContext);
    const dispatchWithParentApp = useDispatchWithParentApp();

    useEffect(() => {
        dispatchWithParentApp(initSpid(formData));
    }, [dispatchWithParentApp, formData]);

    const initSpidStatus = useSelector(state => state.initSpid.status);

    const handleErrorMessage = error => {
        switch (error.code) {
            case "500":
            case "502":
            default:
                return "Ops. Qualcosa è andato storto. Riprova più tardi.";
        }
    };

    return (
        <>
            {initSpidStatus.started && (
                <ContentContainer data-testid="loading-feedback-container">
                    <IconsContainer>
                        <LoadingIcon icon={faCircleNotch} className="fa-spin" />
                    </IconsContainer>
                    <H3 color="yankeesBlue" weight="bold">
                        <FormattedMessage id="c-new-spid-modal-content.feedback.loading.title" />
                    </H3>
                    <DescriptionContainer>
                        <BodyCopy4 color="cadet">
                            <FormattedMessage id="c-new-spid-modal-content.feedback.loading.description" />
                        </BodyCopy4>
                    </DescriptionContainer>
                </ContentContainer>
            )}
            {!initSpidStatus.started && initSpidStatus.error && (
                <>
                    <ContentContainer data-testid="feedback-container">
                        <IconsContainer>
                            <ErrorIcon icon={faTimesCircle} />
                        </IconsContainer>
                        <H3 color="yankeesBlue" weight="bold">
                            <FormattedMessage id="c-new-spid-modal-content.feedback.error.title" />{" "}
                        </H3>
                        <DescriptionContainer>
                            <BodyCopy4 color="cadet">{handleErrorMessage(initSpidStatus.errorInfo)}</BodyCopy4>
                        </DescriptionContainer>
                    </ContentContainer>
                    <Footer>
                        <Button
                            kind="primary"
                            data-testid="retry-button"
                            onClick={() => dispatchWithParentApp(initSpid(formData))}
                        >
                            <FormattedMessage id="general.retry" />
                        </Button>
                        <Button kind="secondary" data-testid="close-button" onClick={onClose}>
                            <FormattedMessage id="general.close" />
                        </Button>
                    </Footer>
                </>
            )}
            {!initSpidStatus.started && !initSpidStatus.error && (
                <>
                    <ContentContainer data-testid="feedback-container">
                        <IconsContainer>
                            <CheckIcon icon={faCheckCircle} />
                        </IconsContainer>
                        <H3 color="yankeesBlue" weight="bold">
                            <FormattedMessage id="c-new-spid-modal-content.feedback.success.title" />
                        </H3>
                        <DescriptionContainer>
                            <BodyCopy4 color="cadet">
                                <FormattedMessage
                                    id="c-new-spid-modal-content.feedback.success.description"
                                    values={{
                                        name: formData.name,
                                        surname: formData.surname,
                                        email: formData.email,
                                        b: chunks => <b>{chunks}</b>
                                    }}
                                />
                            </BodyCopy4>
                        </DescriptionContainer>
                    </ContentContainer>
                    <Footer>
                        <Button data-testid="close-button" onClick={onClose}>
                            <FormattedMessage id="general.close" />
                        </Button>
                    </Footer>
                </>
            )}
        </>
    );
};

Feedback.propTypes = {
    onClose: PropTypes.func
};

export default Feedback;
