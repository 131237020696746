import React from "react";
import PropTypes from "prop-types";

import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

import { BodyCopy4 } from "../vapor-components/typography";
import {
    ChartContainer,
    Container,
    CounterContainer,
    LegendContainer,
    LegendElement,
    LegendSquare,
    LegendText,
    SlotsCounterContainer,
    SlotsDescriptionContainer,
    TotalSlotsNumber,
    UsedSlotsNumber
} from "./styled";
import { FormattedMessage } from "react-intl";

const VideoConsumingChart = ({ spidSlots }) => {
    let data = [
        {
            name: <FormattedMessage id="c-spid-consuming-chart.used" />,
            value:
                spidSlots.SPID_VIDEO.extraUsageAvailable && spidSlots.SPID_VIDEO.usedSlots > 0
                    ? 10
                    : spidSlots.SPID_VIDEO.usedSlots
        },
        {
            name: <FormattedMessage id="c-spid-consuming-chart.available" />,
            value: spidSlots.SPID_VIDEO.extraUsageAvailable
                ? 100
                : spidSlots.SPID_VIDEO.totalSlots - spidSlots.SPID_VIDEO.usedSlots
        }
    ];

    if (spidSlots.SPID_VIDEO.extraUsageAvailable) {
        data.push(
            {
                name: "extradata",
                value: 2
            },
            {
                name: "extradata",
                value: 2
            },
            {
                name: "extradata",
                value: 2
            }
        );
    }

    return (
        <Container>
            <ChartContainer>
                <ResponsiveContainer width="99%" maxHeight={164} aspect={2.5}>
                    <PieChart>
                        <Pie
                            data={data}
                            dataKey="value"
                            cx={"50%"}
                            cy={"100%"}
                            startAngle={180}
                            endAngle={0}
                            innerRadius="150%"
                            outerRadius="180%"
                            paddingAngle={1}
                        >
                            <Cell fill="#a247b9" />
                            <Cell fill="#d5b6e1" />
                            {spidSlots.SPID_VIDEO.extraUsageAvailable && (
                                <>
                                    <Cell fill="#d5b6e1" />
                                    <Cell fill="#d5b6e1" />
                                    <Cell fill="#d5b6e1" />
                                </>
                            )}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                <CounterContainer>
                    <SlotsCounterContainer>
                        <UsedSlotsNumber>{spidSlots.SPID_VIDEO.usedSlots}</UsedSlotsNumber>
                        <div>/</div>
                        <TotalSlotsNumber>
                            {spidSlots.SPID_VIDEO.extraUsageAvailable ? (
                                <FormattedMessage id="general.unlimited" />
                            ) : (
                                spidSlots.SPID_VIDEO.totalSlots
                            )}
                        </TotalSlotsNumber>
                    </SlotsCounterContainer>
                    <SlotsDescriptionContainer>
                        <BodyCopy4 color="quickSilver">
                            <FormattedMessage id="c-video-consuming-chart.used-video-slot" />
                        </BodyCopy4>
                    </SlotsDescriptionContainer>
                </CounterContainer>
            </ChartContainer>
            <LegendContainer>
                <LegendElement>
                    <LegendSquare backgroundColor="#a247b9"></LegendSquare>
                    <BodyCopy4>
                        <LegendText>
                            <FormattedMessage id="general.used" />: {spidSlots.SPID_VIDEO.usedSlots}
                        </LegendText>
                    </BodyCopy4>
                </LegendElement>
                <LegendElement>
                    <LegendSquare backgroundColor="#d5b6e1"></LegendSquare>
                    <BodyCopy4>
                        <LegendText>
                            <FormattedMessage id="c-spid-consuming-chart.available" />:{" "}
                            {spidSlots.SPID_VIDEO.extraUsageAvailable ? (
                                <FormattedMessage id="general.unlimited" />
                            ) : (
                                spidSlots.SPID_VIDEO.totalSlots - spidSlots.SPID_VIDEO.usedSlots
                            )}
                        </LegendText>
                    </BodyCopy4>
                </LegendElement>
            </LegendContainer>
        </Container>
    );
};

VideoConsumingChart.defaultProps = {
    spidSlots: {
        SPID_VIDEO: {
            totalSlots: 0,
            usedSlots: 0,
            extraUsageAvailable: false
        }
    }
};

VideoConsumingChart.propTypes = {
    spidSlots: PropTypes.shape({
        SPID_VIDEO: PropTypes.shape({
            totalSlots: PropTypes.number,
            usedSlots: PropTypes.number,
            extraUsageAvailable: PropTypes.bool
        })
    })
};

export default VideoConsumingChart;
