import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "@ts-digital/vrc";
import styled from "styled-components";

export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
`;

export const IconsContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
`;

export const LoadingIcon = styled(FontAwesomeIcon)`
    font-size: 3.5rem;
    color: ${props => props.theme.colors.primary.richElectricBlue};
`;

export const EmptySpidMessageContainer = styled.div`
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`;

export const EmptySpidListIcon = styled.div`
    color: ${props => props.theme.colors.text.quickSilver};
    padding: 1.5rem;
    border-radius: 100%;
    background-color: ${props => props.theme.colors.background.azureishWhite};
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FilterContainer = styled.tr`
    background-color: ${props => props.theme.colors.background.whiteSmoke};
`;

export const TableContainer = styled.div`
    overflow-x: auto;
    min-height: 40vh;
`;

export const CustomSelect = styled(Select)`
    margin-top: -4px;
`;

export const StatusContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const RefreshSpidRequestButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1.5rem;
`;

export const PaginationWrapper = styled.div`
    padding-top: 1rem;
    padding-bottom: 2rem;
`;

export const StyledDateInput = styled.input.attrs(() => ({
    type: "date"
}))`
    box-sizing: border-box;
    display: block;
    flex: 1;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-indent: 8px;
    background: ${props => props.theme.colors.background.whiteSmoke};
    border: 1px solid
        ${props => {
            if (props.error) {
                return props.theme.colors.status.maximumRed;
            } else {
                return props.theme.colors.primary.columbiaBlue;
            }
        }};
    font-size: 100%;
    color: ${props => props.theme.colors.text.yankeesBlue};
    transition: background 0.3s ease, border 0.3s ease;

    &::placeholder {
        color: ${props => props.theme.colors.text.quickSilver};
    }
    &:hover,
    &:focus,
    &:active {
        border: 1px solid
            ${props => {
                if (props.error) {
                    return props.theme.colors.status.maximumRed;
                } else {
                    return props.theme.colors.primary.richElectricBlue;
                }
            }};
        outline: 0px;
    }

    &:read-only {
        background-color: ${props => props.theme.colors.background.white};
        border: 1px solid rgba(0, 0, 0, 0);
    }

    &:disabled {
        background: ${props => props.theme.colors.text.ghostWhite};
        cursor: not-allowed;
        color: ${props => props.theme.colors.text.cadet};
        border: 1px solid ${props => props.theme.colors.text.silverSand};
        &::placeholder {
            color: ${props => props.theme.colors.text.silverSand};
            opacity: 0.64;
        }
    }
    margin-top: -4px;
`;
