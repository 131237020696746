import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 1rem;
    right: 2rem;
    gap: 0.5rem;
    z-index: 9;
`;
