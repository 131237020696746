import React, { useCallback, useContext } from "react";

import { fieldLength, fieldRequired } from "@ts-digital/agyo-validations";
import combineValidators from "@ts-digital/agyo-validations/lib/utils/combineValidators";
import { Button, Input } from "@ts-digital/vrc";
import { EmailValidator } from "commons-validator-js";
import { Field, Form } from "react-final-form";
import { FormattedMessage, useIntl } from "react-intl";

import { BodyCopy4, Descriptive, H3 } from "../../../vapor-components/typography";
import { CreateNewSpidContext } from "../../../../providers/create-new-spid-provider";
import { EmailAlertContainer, Footer, FormContainer } from "./styled";
import EmptyElement from "../../../empty-element";

const OwnerData = () => {
    const { formData, nextStep, previousStep, setFormData } = useContext(CreateNewSpidContext);
    const intl = useIntl();

    const handleSubmit = useCallback(
        values => {
            setFormData({
                ...formData,
                ...values
            });
        },
        [formData, setFormData]
    );

    const normalizeTaxId = value => {
        return value.toUpperCase();
    };

    const normalizeEmail = value => {
        return value.toLowerCase();
    };

    return (
        <Form onSubmit={handleSubmit}>
            {props => (
                <>
                    <div data-testid="owner-data-container">
                        <H3 color="yankeesBlue" weight="bold">
                            <FormattedMessage id="c-new-spid-modal-content.owner-data.title" />
                        </H3>
                        <BodyCopy4 color="cadet">
                            <FormattedMessage
                                id="c-new-spid-modal-content.owner-data.description"
                                values={{
                                    br: <br />
                                }}
                            />
                        </BodyCopy4>
                        <FormContainer>
                            <Field name="name" validate={fieldRequired("Campo obbligatorio")}>
                                {({ input, meta, props }) => (
                                    <Input
                                        {...input}
                                        {...props}
                                        label={intl.formatMessage({
                                            id: "c-new-spid-modal-content.owner-data.field.name.label"
                                        })}
                                        placeholder={intl.formatMessage({
                                            id: "c-new-spid-modal-content.owner-data.field.name.placeholder"
                                        })}
                                        required={true}
                                        error={meta.touched === true && meta.error !== undefined}
                                        errorMessage={meta.error}
                                    />
                                )}
                            </Field>
                            <Field name="surname" validate={fieldRequired("Campo obbligatorio")}>
                                {({ input, meta, props }) => (
                                    <Input
                                        {...input}
                                        {...props}
                                        label={intl.formatMessage({
                                            id: "c-new-spid-modal-content.owner-data.field.surname.label"
                                        })}
                                        placeholder={intl.formatMessage({
                                            id: "c-new-spid-modal-content.owner-data.field.surname.placeholder"
                                        })}
                                        required={true}
                                        error={meta.touched === true && meta.error !== undefined}
                                        errorMessage={meta.error}
                                    />
                                )}
                            </Field>
                            <Field
                                name="taxId"
                                validate={combineValidators(
                                    fieldRequired("Campo obbligatorio"),
                                    fieldLength("Codice fiscale non valido", 16, 16)
                                )}
                                parse={normalizeTaxId}
                            >
                                {({ input, meta, props }) => (
                                    <Input
                                        {...input}
                                        {...props}
                                        label={intl.formatMessage({
                                            id: "c-new-spid-modal-content.owner-data.field.taxid.label"
                                        })}
                                        placeholder={intl.formatMessage({
                                            id: "c-new-spid-modal-content.owner-data.field.taxid.placeholder"
                                        })}
                                        required={true}
                                        error={meta.touched === true && meta.error !== undefined}
                                        errorMessage={meta.error}
                                    />
                                )}
                            </Field>
                            <EmptyElement />
                            <Field
                                name="email"
                                validate={combineValidators(fieldRequired("Campo obbligatorio"), value =>
                                    new EmailValidator().isValid(value) ? null : "Email non valida"
                                )}
                                parse={normalizeEmail}
                            >
                                {({ input, meta, props }) => (
                                    <Input
                                        {...input}
                                        {...props}
                                        label={intl.formatMessage({
                                            id: "c-new-spid-modal-content.owner-data.field.email.label"
                                        })}
                                        placeholder={intl.formatMessage({
                                            id: "c-new-spid-modal-content.owner-data.field.email.placeholder"
                                        })}
                                        required={true}
                                        error={meta.touched === true && meta.error !== undefined}
                                        errorMessage={meta.error}
                                    />
                                )}
                            </Field>
                            <EmailAlertContainer>
                                <Descriptive color="quickSilver">
                                    <FormattedMessage id="c-new-spid-modal-content.owner-data.field.email.disclamer" />
                                </Descriptive>
                            </EmailAlertContainer>
                        </FormContainer>
                    </div>
                    <Footer>
                        <Button
                            kind="secondary"
                            onClick={() => {
                                formData.raoId && setFormData({ ...formData, raoId: "" });
                                previousStep();
                            }}
                        >
                            <FormattedMessage id="general.back" />
                        </Button>
                        <Button
                            disabled={!props.form.getState().valid}
                            onClick={() => !props.handleSubmit() && nextStep()}
                        >
                            <FormattedMessage id="general.continue" />
                        </Button>
                    </Footer>
                </>
            )}
        </Form>
    );
};

export default OwnerData;
