import React from "react";

import { Provider } from "react-redux";
import { ThemeProvider } from "@ts-digital/vrc";
import ReactDOM from "react-dom";
import VaporThemeProvider from "@vapor/material/styles";

import { GlobalStyles } from "./globalStyles";
import HandlerRoutes from "./views";
import { ParentAppProvider } from "./providers";
import reportWebVitals from "./reportWebVitals";
import store from "./reducers";
import ToastProvider from "./providers/toast-provider";
import { FeatureContextProvider } from "./providers/feature-context-provider";

ReactDOM.render(
    <Provider store={store}>
        <VaporThemeProvider>
            <ThemeProvider>
                <FeatureContextProvider>
                    <ParentAppProvider>
                        <ToastProvider>
                            <HandlerRoutes />
                            <GlobalStyles />
                        </ToastProvider>
                    </ParentAppProvider>
                </FeatureContextProvider>
            </ThemeProvider>
        </VaporThemeProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
