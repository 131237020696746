import { API_WRITE_URL } from "../config";
import { postRestCall } from "../utils/rest-api-call";

export const REFRESH_SESSION_START = "REFRESH_SESSION_START";
export const REFRESH_SESSION_ERROR = "REFRESH_SESSION_ERROR";
export const REFRESH_SESSION_SUCCESS = "REFRESH_SESSION_SUCCESS";
export const REFRESH_SESSION_RESET = "REFRESH_SESSION_RESET";

export function refreshSession(spidId) {
    return async (dispatch, parentApp) => {
        dispatch({
            type: REFRESH_SESSION_START
        });

        try {
            const response = await postRestCall(
                `${API_WRITE_URL}/api/v1/spid/${spidId}/refreshSession`,
                {},
                {},
                parentApp
            );
            dispatch({
                type: REFRESH_SESSION_SUCCESS,
                payload: { ...response }
            });
        } catch (error) {
            dispatch({
                type: REFRESH_SESSION_ERROR,
                error: error
            });
        }
    };
}

export function resetRefreshSessionStatus() {
    return dispatch => {
        dispatch({
            type: REFRESH_SESSION_RESET
        });
    };
}
