import React, { useContext } from "react";
import PropTypes from "prop-types";

import StepIndicator from "../../../vapor-components/step-indicator";
import { CreateNewSpidContext } from "../../../../providers/create-new-spid-provider";
import Entity from "../../steps-content/entity";
import Feedback from "../../steps-content/feedback";
import OwnerData from "../../steps-content/owner-data";
import Typology from "../../steps-content/typology";
import { ContentContainer } from "./styled";
import Recognition from "../../steps-content/recognition";

const ModalContent = ({ setIsModalOpen, spidSlots, steps }) => {
    const { currentStep } = useContext(CreateNewSpidContext);

    return (
        <ContentContainer>
            <StepIndicator steps={steps} currentStep={currentStep} />
            {currentStep === 0 && <Entity onClose={() => setIsModalOpen(false)} />}
            {currentStep === 1 && <Typology spidSlots={spidSlots} />}
            {currentStep === 2 && <Recognition spidSlots={spidSlots} />}
            {currentStep === 3 && <OwnerData />}
            {currentStep === 4 && <Feedback onClose={() => setIsModalOpen(false)} />}
        </ContentContainer>
    );
};

ModalContent.propTypes = {
    spidSlots: PropTypes.shape({
        SPID: PropTypes.shape({
            totalSlots: PropTypes.number,
            usedSlots: PropTypes.number,
            extraUsageAvailable: PropTypes.bool
        }),
        SPID_VIDEO: PropTypes.shape({
            totalSlots: PropTypes.number,
            usedSlots: PropTypes.number,
            extraUsageAvailable: PropTypes.bool
        })
    }),
    setIsModalOpen: PropTypes.func.isRequired,
    steps: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ModalContent;
