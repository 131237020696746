import {
    DELETE_SPID_REQUEST_ERROR,
    DELETE_SPID_REQUEST_START,
    DELETE_SPID_REQUEST_SUCCESS,
    DELETE_SPID_REQUEST_RESET
} from "../actions/deleteSpidRequest";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function deleteSpidRequest(state = defaultState, { type, payload, error }) {
    switch (type) {
        case DELETE_SPID_REQUEST_START:
            return {
                ...defaultState,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case DELETE_SPID_REQUEST_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                payload
            };
        case DELETE_SPID_REQUEST_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { message: error.message }
                }
            };
        case DELETE_SPID_REQUEST_RESET:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
