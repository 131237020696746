import styled from "styled-components";
import { Th } from "..";

export const ClickableTh = styled(Th)`
    cursor: pointer;

    :hover {
        box-shadow: inset 0 0 0 1px #0090d1;
    }
`;

export const IconContainer = styled.span`
    margin-left: 0.25rem;
`;
