import { API_WRITE_URL } from "../config";
import { postRestCall } from "../utils/rest-api-call";

export const INIT_SPID_START = "INIT_SPID_START";
export const INIT_SPID_ERROR = "INIT_SPID_ERROR";
export const INIT_SPID_SUCCESS = "INIT_SPID_SUCCESS";
export const INIT_SPID_RESET = "INIT_SPID_RESET";

export function initSpid(formData) {
    return async (dispatch, parentApp) => {
        dispatch({
            type: INIT_SPID_START
        });

        try {
            const response = await postRestCall(
                `${API_WRITE_URL}/api/v1/spid`,
                {},
                {
                    itemId: parentApp.data.item.id,
                    name: formData.name,
                    surname: formData.surname,
                    taxId: formData.taxId,
                    email: formData.email,
                    channels: formData.channels,
                    raoId: formData.raoId,
                    spidType: formData.spidType,
                    spidLevel: "SPID_LEVEL_2",
                    sendNotification: true
                },
                parentApp
            );
            dispatch({
                type: INIT_SPID_SUCCESS,
                payload: { ...response }
            });
        } catch (error) {
            dispatch({
                type: INIT_SPID_ERROR,
                error: error
            });
        }
    };
}

export function resetInitSpidStatus() {
    return dispatch => {
        dispatch({
            type: INIT_SPID_RESET
        });
    };
}
