import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAlphaDown, faSortAlphaUp, faSortNumericDown, faSortNumericUp } from "@fortawesome/pro-regular-svg-icons";

import { ClickableTh, IconContainer } from "./styled";
import { SORT_DIRECTIONS } from "../../../../utils/constants";

const SortableCell = ({ children, onClick, sort, type }) => {
    const handleChangeSortIcon = sort => {
        switch (sort) {
            case SORT_DIRECTIONS.Ascending:
                return <FontAwesomeIcon icon={type === "numeric" ? faSortNumericUp : faSortAlphaUp} />;
            case SORT_DIRECTIONS.Descending:
                return <FontAwesomeIcon icon={type === "numeric" ? faSortNumericDown : faSortAlphaDown} />;
            default:
                return null;
        }
    };

    return (
        <ClickableTh onClick={onClick}>
            {children}
            <IconContainer>{handleChangeSortIcon(sort)}</IconContainer>
        </ClickableTh>
    );
};

SortableCell.propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.func,
    sort: PropTypes.oneOf([SORT_DIRECTIONS.Ascending, SORT_DIRECTIONS.Descending]),
    type: PropTypes.oneOf(["alpha", "numeric"])
};

export default SortableCell;
