import {
    SEND_SESSION_LINK_ERROR,
    SEND_SESSION_LINK_START,
    SEND_SESSION_LINK_SUCCESS
} from "../actions/sendSessionLink";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function sendSessionLink(state = defaultState, { type, payload, error }) {
    switch (type) {
        case SEND_SESSION_LINK_START:
            return {
                ...defaultState,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case SEND_SESSION_LINK_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                payload
            };
        case SEND_SESSION_LINK_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { message: error.message }
                }
            };
        default:
            return state;
    }
}
