import PropTypes from "prop-types";
import { useEffect } from "react";

import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Drawer,
    Grid,
    HeaderBar,
    IconButton,
    Typography
} from "@vapor/material";
import isEqual from "lodash.isequal";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { getRaoById } from "../../actions/getRaoById";
import { useDispatchWithParentApp } from "../../hooks/use-dispatch-with-parent-app";
import { CHANNELS, SPID_STATUS } from "../../utils/constants";

const DetailsDrawer = ({
    formData,
    onClose,
    open,
    setIsDeleteSpidRequestModalOpen,
    setIsModifySpidRequestDrawerOpen,
    setIsShareSpidRequestModalOpen,
    setSelectedSpidRequestToDelete,
    setSelectedSpidRequestToEdit,
    setShareSpidRequestModalValues
}) => {
    const dispatchWithParentApp = useDispatchWithParentApp();
    const intl = useIntl();

    useEffect(() => {
        if (formData?.raoId) {
            dispatchWithParentApp(getRaoById(formData.raoId));
        }
    }, [dispatchWithParentApp, formData]);

    const rao = useSelector(state => state.getRaoById?.payload?.content[0]);
    const getRaoByIdStatus = useSelector(state => state.getRaoById.status);

    const handleShowCorrectChannelLabel = (cie, cns, feq, video, rao) => {
        const currentChannels = CHANNELS.InitialChannels;
        currentChannels.cie = cie;
        currentChannels.cns = cns;
        currentChannels.feq = feq;
        currentChannels.video = video;
        currentChannels.rao = rao;

        if (isEqual(currentChannels, CHANNELS.CieCnsFeq)) {
            return <FormattedMessage id="spid-recognition-method.CIE-CNS-FEQ" />;
        } else if (isEqual(currentChannels, CHANNELS.Video)) {
            return <FormattedMessage id="spid-recognition-method.VIDEO" />;
        } else if (isEqual(currentChannels, CHANNELS.Rao)) {
            return <FormattedMessage id="spid-recognition-method.RAO" />;
        } else {
            return "";
        }
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose} hideBackdrop>
            <HeaderBar
                divider
                size="small"
                rightItems={[
                    <IconButton onClick={onClose} color="primary">
                        <FontAwesomeIcon icon={faTimes} />
                    </IconButton>
                ]}
                title={<FormattedMessage id="c-details-drawer.title" />}
            />
            <Grid container sx={{ p: 6 }} spacing={3}>
                <Grid item xs={5}>
                    <Typography variant="bodyLarge500">
                        <FormattedMessage id="general.typology" />
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="body">
                        <FormattedMessage id={`spid-type.${formData?.spidType}`} />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider light />
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="bodyLarge500">
                        <FormattedMessage id="c-details-drawer.recipient-data" />
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: "16px", flexWrap: "wrap" }}>
                        <Typography variant="body">
                            <Box>
                                {formData?.name} {formData?.surname}
                            </Box>
                            <Box>{formData?.taxId}</Box>
                            <Box>{formData?.email}</Box>
                        </Typography>
                        <Button
                            sx={{ flexShrink: 0 }}
                            disabled={formData?.status !== SPID_STATUS.Initialized}
                            onClick={() => {
                                onClose();
                                setIsModifySpidRequestDrawerOpen(true);
                                setSelectedSpidRequestToEdit({
                                    spidType: formData?.spidType,
                                    name: formData?.name,
                                    surname: formData?.surname,
                                    taxId: formData?.taxId,
                                    email: formData?.email,
                                    raoId: formData?.raoId,
                                    channels: {
                                        cie: formData?.channels.cie,
                                        cns: formData?.channels.cns,
                                        feq: formData?.channels.feq,
                                        video: formData?.channels.video,
                                        rao: formData?.channels.rao
                                    }
                                });
                            }}
                        >
                            <FormattedMessage id="general.modify" />
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider light />
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="bodyLarge500">
                        <FormattedMessage id="general.recognition-method" />
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="body">
                        <Box>
                            {handleShowCorrectChannelLabel(
                                formData?.channels.cie,
                                formData?.channels.cns,
                                formData?.channels.feq,
                                formData?.channels.video,
                                formData?.channels.rao
                            )}
                            {formData?.channels.rao && (
                                <>
                                    , <FormattedMessage id="general.at" />:
                                </>
                            )}
                        </Box>
                        {getRaoByIdStatus.ended && rao && (
                            <>
                                <Box>{rao.name}</Box>
                                <Box>{rao.address}</Box>
                                <Box>
                                    {rao.city_name} ({rao.province}) {rao.postal_code}
                                </Box>
                            </>
                        )}
                        {getRaoByIdStatus.started && <CircularProgress />}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider light />
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="bodyLarge500">
                        <FormattedMessage id="c-details-drawer.request-date" />
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="body">
                        {intl.formatDate(formData?.requestedAt, {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric"
                        })}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider light />
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="bodyLarge500">
                        <FormattedMessage id="c-details-drawer.request-status" />
                    </Typography>
                </Grid>
                <Grid item xs={7} sx={{ marginBottom: "100px" }}>
                    <Typography variant="body">
                        <FormattedMessage id={`spid-status.${formData?.status}`} />
                    </Typography>
                </Grid>
                {!formData?.channels.rao && (
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            sx={{ width: "100%" }}
                            disabled={formData?.status !== SPID_STATUS.Initialized}
                            onClick={() => {
                                onClose();
                                setIsShareSpidRequestModalOpen(true);
                                setShareSpidRequestModalValues({
                                    id: formData?.id,
                                    name: formData?.name,
                                    surname: formData?.surname,
                                    sessionLink: formData?.sessionLink
                                });
                            }}
                        >
                            <FormattedMessage id="general.share" />
                        </Button>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Button
                        color="error"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        disabled={
                            formData?.status !== SPID_STATUS.Initialized &&
                            formData?.status !== SPID_STATUS.SessionExpired
                        }
                        onClick={() => {
                            onClose();
                            setIsDeleteSpidRequestModalOpen(true);
                            setSelectedSpidRequestToDelete({
                                id: formData?.id,
                                name: formData?.name,
                                surname: formData?.surname
                            });
                        }}
                    >
                        <FormattedMessage id="general.delete" />
                    </Button>
                </Grid>
            </Grid>
        </Drawer>
    );
};

DetailsDrawer.propTypes = {
    formData: PropTypes.shape({
        spidType: PropTypes.oneOf([
            "INDIVIDUAL",
            "LEGAL_ENTITY",
            "PROFESSIONAL_INDIVIDUAL",
            "PROFESSIONAL_LEGAL_ENTITY"
        ]).isRequired,
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
        taxId: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        raoId: PropTypes.string,
        requestedAt: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        channels: PropTypes.shape({
            cie: PropTypes.bool,
            cns: PropTypes.bool,
            feq: PropTypes.bool,
            video: PropTypes.bool,
            rao: PropTypes.bool
        }).isRequired
    }),
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setIsDeleteSpidRequestModalOpen: PropTypes.func.isRequired,
    setIsModifySpidRequestDrawerOpen: PropTypes.func.isRequired,
    setIsShareSpidRequestModalOpen: PropTypes.func.isRequired,
    setSelectedSpidRequestToDelete: PropTypes.func.isRequired,
    setShareSpidRequestModalValues: PropTypes.func.isRequired
};

export default DetailsDrawer;
