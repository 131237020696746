import { useCallback, useContext } from "react";

import { useDispatch } from "react-redux";

import { ParentAppContext } from "../providers";

export const useDispatchWithParentApp = () => {
    const dispatch = useDispatch();
    const { parentApp } = useContext(ParentAppContext);

    const fn = useCallback(action => action(dispatch, parentApp), [dispatch, parentApp]);
    return fn;
};
