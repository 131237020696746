import React from "react";
import PropTypes from "prop-types";

import { CreateNewSpidProvider } from "../../providers/create-new-spid-provider";
import ModalContent from "./components/modal-content";

const steps = ["Soggetto", "Tipologia", "Riconoscimento", "Dati", "Esito"];

const CreateNewSpidModalContent = ({ setIsModalOpen, spidSlots }) => {
    const initialFormData = {
        entity: "",
        spidType: "",
        channels: {},
        name: "",
        surname: "",
        taxId: "",
        raoId: "",
        email: ""
    };

    return (
        <CreateNewSpidProvider initialFormData={initialFormData} stepCount={steps.length}>
            <ModalContent setIsModalOpen={setIsModalOpen} steps={steps} spidSlots={spidSlots} />
        </CreateNewSpidProvider>
    );
};

CreateNewSpidModalContent.propTypes = {
    setIsModalOpen: PropTypes.func.isRequired,
    spidSlots: PropTypes.shape({
        SPID: PropTypes.shape({
            totalSlots: PropTypes.number,
            usedSlots: PropTypes.number,
            extraUsageAvailable: PropTypes.bool
        }),
        SPID_VIDEO: PropTypes.shape({
            totalSlots: PropTypes.number,
            usedSlots: PropTypes.number,
            extraUsageAvailable: PropTypes.bool
        })
    })
};

export default CreateNewSpidModalContent;
