import { API_WRITE_URL } from "../config";
import { deleteRestCall } from "../utils/rest-api-call";

export const DELETE_SPID_REQUEST_START = "DELETE_SPID_REQUEST_START";
export const DELETE_SPID_REQUEST_ERROR = "DELETE_SPID_REQUEST_ERROR";
export const DELETE_SPID_REQUEST_SUCCESS = "DELETE_SPID_REQUEST_SUCCESS";
export const DELETE_SPID_REQUEST_RESET = "DELETE_SPID_REQUEST_RESET";

export function deleteSpidRequest(spidId) {
    return async (dispatch, parentApp) => {
        dispatch({
            type: DELETE_SPID_REQUEST_START
        });

        try {
            const response = await deleteRestCall(`${API_WRITE_URL}/api/v1/spid/${spidId}`, {}, {}, parentApp);
            dispatch({
                type: DELETE_SPID_REQUEST_SUCCESS,
                payload: { ...response }
            });
        } catch (error) {
            dispatch({
                type: DELETE_SPID_REQUEST_ERROR,
                error: error
            });
        }
    };
}

export function resetDeleteSpidRequestStatus() {
    return dispatch => {
        dispatch({
            type: DELETE_SPID_REQUEST_RESET
        });
    };
}
