import { applyMiddleware, createStore, compose, combineReducers } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";

import { ENABLE_CONSOLE_LOGS } from "../config";

import reducersSchema from "./schema";

const logger = createLogger({ collapsed: true });

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk];

const isRunningTest = "test" === process.env.NODE_ENV;

if (ENABLE_CONSOLE_LOGS && !isRunningTest) {
    console.log("Enable console logs", ENABLE_CONSOLE_LOGS);

    middlewares.push(logger);
}

const store = createStore(combineReducers(reducersSchema), composeEnhancers(applyMiddleware(...middlewares)));

export default store;
