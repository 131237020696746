import { API_READ_URL } from "../config";
import { getRestCall } from "../utils/rest-api-call";

export const GET_PROVINCE_START = "GET_PROVINCE_START";
export const GET_PROVINCE_ERROR = "GET_PROVINCE_ERROR";
export const GET_PROVINCE_SUCCESS = "GET_PROVINCE_SUCCESS";

export function getProvince() {
    return async (dispatch, parentApp) => {
        dispatch({
            type: GET_PROVINCE_START
        });

        try {
            const response = await getRestCall(
                `${API_READ_URL}/api/v1/spid/get/provinces`,
                {
                    nationCode: "IT"
                },
                parentApp
            );

            dispatch({
                type: GET_PROVINCE_SUCCESS,
                payload: response
            });
        } catch (error) {
            dispatch({
                type: GET_PROVINCE_ERROR,
                error: error
            });
        }
    };
}
