import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;
