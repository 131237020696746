import React from "react";
import PropTypes from "prop-types";

import { Label, ListContainer, Number, Step, StepLi } from "./styled";

const StepIndicator = ({ currentStep, steps }) => (
    <ListContainer>
        {steps.map((step, key) => {
            const index = steps.indexOf(step);
            return (
                <StepLi key={`step_${key}`} index={index} currentStep={currentStep} isLast={index + 1 === steps.length}>
                    <Step>
                        <Number index={index} currentStep={currentStep}>
                            {index + 1}
                        </Number>
                        <Label>{step}</Label>
                    </Step>
                </StepLi>
            );
        })}
    </ListContainer>
);

StepIndicator.propTypes = {
    currentStep: PropTypes.number.isRequired,
    steps: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default StepIndicator;
