import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0px;
    font-size: 16px;
  }

  * {
    box-sizing: border-box;
  }
`;
