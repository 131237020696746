import { API_READ_URL } from "../config";
import { getRestCall } from "../utils/rest-api-call";

export const GET_SPID_LIST_START = "GET_SPID_LIST_START";
export const GET_SPID_LIST_ERROR = "GET_SPID_LIST_ERROR";
export const GET_SPID_LIST_SUCCESS = "GET_SPID_LIST_SUCCESS";

export function getSpidList(
    page,
    sortBy,
    sortDirection,
    fullNameOrTaxId,
    email,
    channels,
    identityTypes,
    requestedAt,
    statuses
) {
    return async (dispatch, parentApp) => {
        dispatch({
            type: GET_SPID_LIST_START,
            payload: {
                page,
                property: sortBy,
                direction: sortDirection,
                fullNameOrTaxId: fullNameOrTaxId,
                email: email,
                channels: channels,
                identityTypes: identityTypes,
                requestedAt: requestedAt,
                statuses: statuses
            }
        });

        try {
            const response = await getRestCall(
                `${API_READ_URL}/api/v1/spid/`,
                {
                    itemId: parentApp.data.item.id,
                    page: page,
                    sortBy: sortBy,
                    sortDirection: sortDirection,
                    fullNameOrTaxId: fullNameOrTaxId,
                    email: email,
                    channels: channels,
                    identityTypes: identityTypes,
                    requestedAt: requestedAt,
                    statuses: statuses
                },
                parentApp
            );
            dispatch({
                type: GET_SPID_LIST_SUCCESS,
                payload: {
                    first: response.first,
                    last: response.last,
                    page: response.pageable.pageNumber,
                    size: response.pageable.pageSize,
                    property: sortBy,
                    direction: sortDirection,
                    content: response.content,
                    totalPages: response.totalPages
                }
            });
        } catch (error) {
            dispatch({
                type: GET_SPID_LIST_ERROR,
                error: error
            });
        }
    };
}
