import React from "react";

import { faClock, faSackDollar } from "@fortawesome/pro-regular-svg-icons";
import { FormattedMessage } from "react-intl";

import { BodyCopy4 } from "../../../../../vapor-components/typography";
import { ListCircleIcon, ListIcon } from "../../styled";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";

const RaoChannel = () => {
    return (
        <>
            <ul className="fa-ul">
                <li>
                    <span className="fa-li">
                        <ListIcon icon={faClock} />
                    </span>
                    <BodyCopy4 color="yankeesBlue">
                        <FormattedMessage
                            id="c-new-spid-modal-content.recognition.rao.complete-time"
                            values={{
                                b: chunks => <b>{chunks}</b>
                            }}
                        />
                    </BodyCopy4>
                </li>
                <li>
                    <span className="fa-li">
                        <ListIcon icon={faSackDollar} />
                    </span>
                    <BodyCopy4 color="yankeesBlue">
                        <FormattedMessage
                            id="c-new-spid-modal-content.recognition.rao.cost"
                            values={{
                                b: chunks => <b>{chunks}</b>
                            }}
                        />
                    </BodyCopy4>
                </li>
            </ul>
            <BodyCopy4 color="yankeesBlue">
                <FormattedMessage id="c-new-spid-modal-content.recognition.rao.description" />
            </BodyCopy4>
            <ul className="fa-ul">
                <li>
                    <span className="fa-li">
                        <ListCircleIcon icon={faCircle} />
                    </span>
                    <BodyCopy4 color="yankeesBlue" weight="bold">
                        <FormattedMessage id="c-new-spid-modal-content.recognition.rao.info.phone-email" />
                    </BodyCopy4>
                </li>
                <li>
                    <span className="fa-li">
                        <ListCircleIcon icon={faCircle} />
                    </span>
                    <BodyCopy4 color="yankeesBlue">
                        <FormattedMessage
                            id="c-new-spid-modal-content.recognition.rao.info.identity-card"
                            values={{
                                b: chunks => <b>{chunks}</b>
                            }}
                        />
                    </BodyCopy4>
                </li>
                <li>
                    <span className="fa-li">
                        <ListCircleIcon icon={faCircle} />
                    </span>
                    <BodyCopy4 color="yankeesBlue" weight="bold">
                        <FormattedMessage id="c-new-spid-modal-content.recognition.rao.info.health-insurance-card" />
                    </BodyCopy4>
                </li>
            </ul>
        </>
    );
};

export default RaoChannel;
