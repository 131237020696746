import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const Footer = styled.div`
    background-color: ${props => props.theme.colors.background.whiteSmoke};
    padding: 0.75rem 0.5rem;
    display: flex;
    justify-content: flex-end;
    gap: 1.5rem;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
`;

export const TypesAndDescriptionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
    margin-top: 3rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

export const TypesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-right: 3rem;
    border-right: 1px solid ${props => props.theme.colors.primary.columbiaBlue};

    @media (max-width: 768px) {
        border-bottom: 1px solid ${props => props.theme.colors.primary.columbiaBlue};
        padding-bottom: 2rem;
        padding-right: initial;
        border-right: none;
    }
`;

export const ListIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.primary.richElectricBlue};
`;

export const ListCircleIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.primary.yankeesBlue};
    font-size: 0.5rem;
`;
