import React from "react";
import PropTypes from "prop-types";

import {
    faCircleNotch,
    faEye,
    faList,
    faPen,
    faShare,
    faShareAlt,
    faTrashAlt
} from "@fortawesome/pro-regular-svg-icons";
import { Input } from "@ts-digital/vrc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip } from "@vapor/material";
import { FormattedMessage, useIntl } from "react-intl";
import isEqual from "lodash.isequal";

import { StyledTable, Td, Th, THead } from "../vapor-components/table";
import { BodyCopy4, H3 } from "../vapor-components/typography";
import {
    CustomSelect,
    EmptySpidListIcon,
    EmptySpidMessageContainer,
    FilterContainer,
    IconsContainer,
    LoadingContainer,
    LoadingIcon,
    PaginationWrapper,
    StatusContainer,
    StyledDateInput,
    TableContainer
} from "./styled";
import SortableCell from "../vapor-components/table/sortable-cell";
import Pagination from "../vapor-components/pagination";
import { CHANNELS, SPID_STATUS, SPID_TYPES } from "../../utils/constants";

const SpidListTable = ({
    currentPage,
    filters,
    getSpidListStatus,
    handleChangeSort,
    onChangeFilters,
    onChangePage,
    onNextPage,
    onPreviousPage,
    setIsDeleteSpidRequestModalOpen,
    setIsModifySpidRequestDrawerOpen,
    setIsRefreshSpidRequestModalOpen,
    setIsShareRaoSpidRequestModalOpen,
    setIsShareSpidRequestModalOpen,
    setSelectedSpidRequestToDelete,
    setSelectedSpidRequestToEdit,
    setSelectedSpidRequestToSeeDetails,
    setSelectedSpidRequestToRefresh,
    setShareSpidRequestModalValues,
    setIsDetailsDrawerOpen,
    sorting,
    spids,
    totalPages
}) => {
    const intl = useIntl();

    const currentSpidListPage = spids?.content || [];
    const isFirst = spids?.first || false;
    const isLast = spids?.last || false;

    const handleShowCorrectChannelLabel = (cie, cns, feq, video, rao) => {
        const currentChannels = CHANNELS.InitialChannels;
        currentChannels.cie = cie;
        currentChannels.cns = cns;
        currentChannels.feq = feq;
        currentChannels.video = video;
        currentChannels.rao = rao;

        if (isEqual(currentChannels, CHANNELS.CieCnsFeq)) {
            return <FormattedMessage id="spid-recognition-method.CIE-CNS-FEQ" />;
        } else if (isEqual(currentChannels, CHANNELS.Video)) {
            return <FormattedMessage id="spid-recognition-method.VIDEO" />;
        } else if (isEqual(currentChannels, CHANNELS.Rao)) {
            return <FormattedMessage id="spid-recognition-method.RAO" />;
        } else {
            return "";
        }
    };

    return (
        <>
            <TableContainer>
                <StyledTable>
                    <THead>
                        <tr>
                            <SortableCell
                                sort={sorting.sortBy === "user.name" ? sorting.sortDirection : undefined}
                                type="alpha"
                                onClick={() => handleChangeSort("user.name")}
                            >
                                <FormattedMessage id="general.user" />
                            </SortableCell>
                            <SortableCell
                                sort={sorting.sortBy === "user.email" ? sorting.sortDirection : undefined}
                                type="alpha"
                                onClick={() => handleChangeSort("user.email")}
                            >
                                <FormattedMessage id="general.email" />
                            </SortableCell>
                            <Th>
                                <FormattedMessage id="general.recognition-method" />
                            </Th>
                            <SortableCell
                                sort={sorting.sortBy === "type" ? sorting.sortDirection : undefined}
                                type="alpha"
                                onClick={() => handleChangeSort("type")}
                            >
                                <FormattedMessage id="general.spid-type" />
                            </SortableCell>
                            <SortableCell
                                sort={sorting.sortBy === "requestedAt" ? sorting.sortDirection : undefined}
                                type="numeric"
                                onClick={() => handleChangeSort("requestedAt")}
                            >
                                <FormattedMessage id="general.creation-date" />
                            </SortableCell>
                            <Th>
                                <FormattedMessage id="general.request-status" />
                            </Th>
                            <Th></Th>
                            <Th></Th>
                            <Th></Th>
                        </tr>
                    </THead>
                    <tbody>
                        <FilterContainer>
                            <Td>
                                <Input
                                    placeholder="Cerca"
                                    onChange={e => onChangeFilters("fullNameOrTaxId", e)}
                                    value={filters.fullNameOrTaxId || ""}
                                />
                            </Td>
                            <Td>
                                <Input
                                    placeholder="Cerca"
                                    onChange={e => onChangeFilters("email", e)}
                                    value={filters.email || ""}
                                />
                            </Td>
                            <Td>
                                <CustomSelect
                                    placeholder="Seleziona"
                                    onChange={e => onChangeFilters("channels", e)}
                                    options={[
                                        {
                                            description: "",
                                            value: ""
                                        },
                                        {
                                            description: intl.formatMessage({
                                                id: "spid-recognition-method.CIE-CNS-FEQ"
                                            }),
                                            // TODO quando verrà reintrodotta la modalità CIE riaggiungerlo alla lista dei value del filtro
                                            value: "CNS, FEQ"
                                        },
                                        {
                                            description: intl.formatMessage({
                                                id: "spid-recognition-method.VIDEO"
                                            }),
                                            value: "VIDEO"
                                        },
                                        {
                                            description: intl.formatMessage({
                                                id: "spid-recognition-method.RAO"
                                            }),
                                            value: "RAO"
                                        }
                                    ]}
                                    value={filters.channels}
                                />
                            </Td>
                            <Td>
                                <CustomSelect
                                    placeholder="Seleziona"
                                    onChange={e => onChangeFilters("identityTypes", e)}
                                    options={[
                                        {
                                            description: "",
                                            value: ""
                                        },
                                        {
                                            description: intl.formatMessage({
                                                id: "spid-type.INDIVIDUAL"
                                            }),
                                            value: SPID_TYPES.Individual
                                        },
                                        {
                                            description: intl.formatMessage({
                                                id: "spid-type.PROFESSIONAL_INDIVIDUAL"
                                            }),
                                            value: SPID_TYPES.ProfessionalIndividual
                                        }
                                    ]}
                                    value={filters.identityTypes}
                                />
                            </Td>
                            <Td>
                                <StyledDateInput
                                    type="date"
                                    placeholder="Seleziona"
                                    onChange={e => onChangeFilters("requestedAt", e.target.value)}
                                    value={filters.requestedAt || ""}
                                />
                            </Td>
                            <Td>
                                <CustomSelect
                                    placeholder="Seleziona"
                                    onChange={e => onChangeFilters("statuses", e)}
                                    options={[
                                        {
                                            description: "",
                                            value: ""
                                        },
                                        {
                                            description: intl.formatMessage({
                                                id: "spid-status.INITIALIZED"
                                            }),
                                            value: SPID_STATUS.Initialized
                                        },
                                        {
                                            description: intl.formatMessage({
                                                id: "spid-status.APPROVED"
                                            }),
                                            value: `${SPID_STATUS.Created},${SPID_STATUS.Approved},${SPID_STATUS.Issuing}`
                                        },
                                        {
                                            description: intl.formatMessage({
                                                id: "spid-status.SESSION_EXPIRED"
                                            }),
                                            value: SPID_STATUS.SessionExpired
                                        },
                                        {
                                            description: intl.formatMessage({
                                                id: "spid-status.HOLD"
                                            }),
                                            value: `${SPID_STATUS.Hold},${SPID_STATUS.Expired},${SPID_STATUS.Revoked},${SPID_STATUS.Issued}`
                                        },
                                        {
                                            description: intl.formatMessage({
                                                id: "spid-status.DELETED"
                                            }),
                                            value: SPID_STATUS.Deleted
                                        }
                                    ]}
                                    value={filters.statuses}
                                />
                            </Td>
                            <Td></Td>
                            <Td></Td>
                            <Td></Td>
                            <Td></Td>
                        </FilterContainer>
                        {!getSpidListStatus.started &&
                            currentSpidListPage.length > 0 &&
                            currentSpidListPage.map((spid, key) => (
                                <tr key={key}>
                                    <Td>
                                        <b>
                                            {spid.user.name} {spid.user.surname}
                                        </b>
                                        <div>{spid.user.taxId}</div>
                                    </Td>
                                    <Td>{spid.user.email}</Td>
                                    <Td>
                                        {handleShowCorrectChannelLabel(
                                            spid.cie,
                                            spid.cns,
                                            spid.feq,
                                            spid.video,
                                            spid.rao
                                        )}
                                    </Td>
                                    <Td>
                                        <FormattedMessage id={`spid-type.${spid.type}`} />
                                    </Td>
                                    <Td>
                                        {intl.formatDate(spid.requestedAt, {
                                            year: "numeric",
                                            month: "numeric",
                                            day: "numeric"
                                        })}
                                    </Td>
                                    <Td>
                                        <StatusContainer>
                                            <FormattedMessage id={`spid-status.${spid.status}`} />
                                            {spid.status === SPID_STATUS.SessionExpired && !spid.raoId && (
                                                <Tooltip
                                                    arrow
                                                    placement="top"
                                                    title={<FormattedMessage id="general.update" />}
                                                >
                                                    <IconButton
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => {
                                                            setIsRefreshSpidRequestModalOpen(true);
                                                            setSelectedSpidRequestToRefresh({
                                                                id: spid.id,
                                                                name: spid.user.name,
                                                                surname: spid.user.surname,
                                                                email: spid.user.email
                                                            });
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faShare} />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </StatusContainer>
                                    </Td>
                                    <Td>
                                        <Tooltip arrow placement="top" title={<FormattedMessage id="general.modify" />}>
                                            <IconButton
                                                color="primary"
                                                size="small"
                                                disabled={spid.status !== SPID_STATUS.Initialized}
                                                onClick={() => {
                                                    setIsModifySpidRequestDrawerOpen(true);
                                                    setSelectedSpidRequestToEdit({
                                                        spidType: spid.type,
                                                        name: spid.user.name,
                                                        surname: spid.user.surname,
                                                        taxId: spid.user.taxId,
                                                        email: spid.user.email,
                                                        raoId: spid.raoId,
                                                        channels: {
                                                            cie: spid.cie,
                                                            cns: spid.cns,
                                                            feq: spid.feq,
                                                            video: spid.video,
                                                            rao: spid.rao
                                                        }
                                                    });
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faPen} />
                                            </IconButton>
                                        </Tooltip>
                                    </Td>
                                    <Td>
                                        <Tooltip arrow placement="top" title={<FormattedMessage id="general.detail" />}>
                                            <IconButton
                                                color="primary"
                                                size="small"
                                                onClick={() => {
                                                    setIsDetailsDrawerOpen(true);
                                                    setSelectedSpidRequestToSeeDetails({
                                                        id: spid.id,
                                                        spidType: spid.type,
                                                        name: spid.user.name,
                                                        surname: spid.user.surname,
                                                        taxId: spid.user.taxId,
                                                        email: spid.user.email,
                                                        raoId: spid.raoId,
                                                        requestedAt: spid.requestedAt,
                                                        status: spid.status,
                                                        sessionLink: spid.sessionLink,
                                                        channels: {
                                                            cie: spid.cie,
                                                            cns: spid.cns,
                                                            feq: spid.feq,
                                                            video: spid.video,
                                                            rao: spid.rao
                                                        }
                                                    });
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faEye} />
                                            </IconButton>
                                        </Tooltip>
                                    </Td>
                                    <Td>
                                        <Tooltip arrow placement="top" title={<FormattedMessage id="general.share" />}>
                                            <IconButton
                                                color="primary"
                                                size="small"
                                                disabled={spid.status !== SPID_STATUS.Initialized}
                                                onClick={() => {
                                                    spid.raoId
                                                        ? setIsShareRaoSpidRequestModalOpen(true)
                                                        : setIsShareSpidRequestModalOpen(true);
                                                    setShareSpidRequestModalValues({
                                                        id: spid.id,
                                                        name: spid.user.name,
                                                        surname: spid.user.surname,
                                                        email: spid.user.email,
                                                        sessionLink: spid.sessionLink
                                                    });
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faShareAlt} />
                                            </IconButton>
                                        </Tooltip>
                                    </Td>
                                    <Td>
                                        <Tooltip arrow placement="top" title={<FormattedMessage id="general.delete" />}>
                                            <IconButton
                                                color="error"
                                                size="small"
                                                disabled={
                                                    spid.status !== SPID_STATUS.Initialized &&
                                                    spid.status !== SPID_STATUS.SessionExpired
                                                }
                                                onClick={() => {
                                                    setIsDeleteSpidRequestModalOpen(true);
                                                    setSelectedSpidRequestToDelete({
                                                        id: spid.id,
                                                        name: spid.user.name,
                                                        surname: spid.user.surname
                                                    });
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </IconButton>
                                        </Tooltip>
                                    </Td>
                                </tr>
                            ))}
                        {!getSpidListStatus.started && currentSpidListPage.length === 0 && (
                            <tr>
                                <td colSpan={7}>
                                    <EmptySpidMessageContainer>
                                        <EmptySpidListIcon>
                                            <FontAwesomeIcon icon={faList} />
                                        </EmptySpidListIcon>
                                        <H3 color="yankeesBlue">
                                            <FormattedMessage id="c-spid-list-table.empty-spid-request-list" />
                                        </H3>
                                        <BodyCopy4 color="cadet">
                                            <FormattedMessage id="c-spid-list-table.empty-spid-suggestion" />
                                        </BodyCopy4>
                                    </EmptySpidMessageContainer>
                                </td>
                            </tr>
                        )}
                        {getSpidListStatus.started && (
                            <tr>
                                <td colSpan={7}>
                                    <LoadingContainer>
                                        <IconsContainer>
                                            <LoadingIcon icon={faCircleNotch} className="fa-spin" />
                                        </IconsContainer>
                                        <H3 color="yankeesBlue" weight="bold">
                                            <FormattedMessage id="general.loading" />
                                        </H3>
                                    </LoadingContainer>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </StyledTable>
            </TableContainer>
            <PaginationWrapper>
                <Pagination
                    currentPage={currentPage}
                    isFirstPage={isFirst}
                    isLastPage={isLast}
                    isLoading={getSpidListStatus.started}
                    onChangePage={onChangePage}
                    onNextPage={onNextPage}
                    onPreviousPage={onPreviousPage}
                    totalPages={totalPages}
                />
            </PaginationWrapper>
        </>
    );
};

SpidListTable.propTypes = {
    currentPage: PropTypes.number.isRequired,
    filters: PropTypes.shape({
        userTaxId: PropTypes.string,
        fullNameOrTaxId: PropTypes.string,
        email: PropTypes.string,
        channels: PropTypes.object,
        identityTypes: PropTypes.string,
        requestedAt: PropTypes.string,
        statuses: PropTypes.string
    }),
    getSpidListStatus: PropTypes.shape({
        started: PropTypes.bool,
        error: PropTypes.bool,
        ended: PropTypes.bool
    }).isRequired,
    handleChangeSort: PropTypes.func.isRequired,
    onChangeFilters: PropTypes.func.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onNextPage: PropTypes.func.isRequired,
    onPreviousPage: PropTypes.func.isRequired,
    setIsDeleteSpidRequestModalOpen: PropTypes.func.isRequired,
    setIsModifySpidRequestDrawerOpen: PropTypes.func.isRequired,
    setIsRefreshSpidRequestModalOpen: PropTypes.func.isRequired,
    setIsShareRaoSpidRequestModalOpen: PropTypes.func.isRequired,
    setIsShareSpidRequestModalOpen: PropTypes.func.isRequired,
    setSelectedSpidRequestToDelete: PropTypes.func.isRequired,
    setSelectedSpidRequestToEdit: PropTypes.func.isRequired,
    setSelectedSpidRequestToSeeDetails: PropTypes.func.isRequired,
    setShareSpidRequestModalValues: PropTypes.func.isRequired,
    setIsDetailsDrawerOpen: PropTypes.func.isRequired,
    sorting: PropTypes.shape({
        sortBy: PropTypes.string,
        sortDirection: PropTypes.string
    }),
    spids: PropTypes.shape({
        pages: PropTypes.objectOf(
            PropTypes.shape({
                content: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.string,
                        type: PropTypes.oneOf([
                            "INDIVIDUAL",
                            "LEGAL_ENTITY",
                            "PROFESSIONAL_INDIVIDUAL",
                            "PROFESSIONAL_LEGAL_ENTITY"
                        ]),
                        status: PropTypes.oneOf(["PENDING", "REVOKED", "ACTIVE", "INITIALIZED", "TO_BE_APPROVED"]),
                        requestedAt: PropTypes.string,
                        updatedAt: PropTypes.string,
                        user: PropTypes.shape({
                            id: PropTypes.string,
                            name: PropTypes.string,
                            surname: PropTypes.string,
                            email: PropTypes.string,
                            taxId: PropTypes.string
                        }),
                        sessionId: PropTypes.string,
                        sessionLink: PropTypes.string
                    })
                ),
                first: PropTypes.bool,
                last: PropTypes.bool
            })
        )
    }),
    totalPages: PropTypes.number
};

export default SpidListTable;
