import { combineReducers } from "redux";

import { GET_RAO_LIST_ERROR, GET_RAO_LIST_RESET, GET_RAO_LIST_START, GET_RAO_LIST_SUCCESS } from "../actions/rao";

const defaultStatus = {
    started: false,
    error: false,
    ended: false,
    errorInfo: {
        code: "",
        message: ""
    }
};

function getRaoListStatus(state = defaultStatus, { type, payload, error }) {
    switch (type) {
        case GET_RAO_LIST_START:
            return {
                ...defaultStatus,
                started: true
            };
        case GET_RAO_LIST_SUCCESS:
            return {
                ...defaultStatus,
                ended: true
            };
        case GET_RAO_LIST_ERROR:
            return {
                ...defaultStatus,
                error: true,
                errorInfo: { message: error }
            };
        case GET_RAO_LIST_RESET:
            return {
                ...defaultStatus
            };
        default:
            return state;
    }
}

const defaultPage = { content: [], first: true, last: false };

const defaultGetRaoListContent = {
    pagination: {
        page: 0,
        pagesSize: 0
    },
    page: defaultPage,
    totalElements: 0,
    totalPages: 0
};

function getRaoListContent(state = defaultGetRaoListContent, { type, payload }) {
    switch (type) {
        case GET_RAO_LIST_SUCCESS:
            return {
                pagination: {
                    page: payload.page,
                    pagesSize: payload.size
                },
                page: { content: payload.content, first: payload.first, last: payload.last },
                totalElements: payload.totalElements,
                totalPages: payload.totalPages
            };
        default:
            return state;
    }
}

export const getRaoList = combineReducers({ status: getRaoListStatus, content: getRaoListContent });
