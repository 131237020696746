import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { faIdCard, faUser, faVideo } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "@ts-digital/vrc";
import { FormattedMessage } from "react-intl";
import isEqual from "lodash.isequal";
import isEmpty from "lodash.isempty";

import { BodyCopy4, H3, H4 } from "../../../vapor-components/typography";
import SpidTypeSelectorBox from "../../components/spid-type-selector-box";
import { CreateNewSpidContext } from "../../../../providers/create-new-spid-provider";
import { Footer, TypesAndDescriptionContainer, TypesContainer } from "./styled";
import CieCnsFeqChannel from "./components/cie-cns-feq-channel";
import VideoChannel from "./components/video-channel";
import { CHANNELS } from "../../../../utils/constants";
import RaoChannel from "./components/rao-channel";
import RaoSelection from "./components/rao-selection";
import { resetGetRaoListStatus } from "../../../../actions/rao";
import { useDispatchWithParentApp } from "../../../../hooks/use-dispatch-with-parent-app";
import { RAO_ENABLED, VIDEO_ENABLED } from "../../../../config";

const Recognition = ({ spidSlots }) => {
    const dispatchWithParentApp = useDispatchWithParentApp();
    const { formData, nextStep, previousStep, setFormData } = useContext(CreateNewSpidContext);

    const [showRaoSelection, setShowRaoSelection] = useState(false);

    const remainingVideoSlots = spidSlots.SPID_VIDEO.totalSlots - spidSlots.SPID_VIDEO.usedSlots;

    const handleChange = value => {
        setFormData({
            ...formData,
            channels: value
        });
    };

    const handleShowTypeDescription = () => {
        if (isEqual(formData.channels, CHANNELS.CieCnsFeq)) {
            return {
                title: <FormattedMessage id="c-new-spid-modal-content.recognition.cieCnsFeqChannels.title" />,
                description: <CieCnsFeqChannel />
            };
        } else if (isEqual(formData.channels, CHANNELS.Video)) {
            return {
                title: spidSlots.SPID_VIDEO.totalSlots > 0 && (
                    <FormattedMessage id="c-new-spid-modal-content.recognition.video.title" />
                ),
                description: <VideoChannel spidSlots={spidSlots} />
            };
        } else if (isEqual(formData.channels, CHANNELS.Rao)) {
            return {
                title: <FormattedMessage id="c-new-spid-modal-content.recognition.rao.title" />,
                description: <RaoChannel />
            };
        } else if (isEqual(formData.channels, CHANNELS.InitialChannels)) {
            return {
                title: <FormattedMessage id="c-new-spid-modal-content.recognition.default.title" />,
                description: (
                    <BodyCopy4 color="cadet">
                        <FormattedMessage id="c-new-spid-modal-content.recognition.default.description" />
                    </BodyCopy4>
                )
            };
        } else {
            return {
                title: <FormattedMessage id="c-new-spid-modal-content.recognition.default.title" />,
                description: (
                    <BodyCopy4 color="cadet">
                        <FormattedMessage id="c-new-spid-modal-content.recognition.default.description" />
                    </BodyCopy4>
                )
            };
        }
    };

    const handleNextStep = () => {
        if (formData.channels.rao && !showRaoSelection) {
            setShowRaoSelection(true);
        } else {
            nextStep();
        }
    };

    return (
        <>
            {!showRaoSelection ? (
                <>
                    <H3 color="yankeesBlue" weight="bold">
                        <FormattedMessage id="c-new-spid-modal-content.recognition.title" />
                    </H3>
                    <BodyCopy4 color="cadet">
                        <FormattedMessage
                            id="c-new-spid-modal-content.recognition.description"
                            values={{
                                b: chunks => <b>{chunks}</b>
                            }}
                        />
                    </BodyCopy4>
                    <TypesAndDescriptionContainer>
                        <TypesContainer>
                            <SpidTypeSelectorBox
                                icon={faIdCard}
                                label={<FormattedMessage id="spid-recognition-method.CIE-CNS-FEQ" />}
                                onChange={handleChange}
                                selected={isEqual(formData.channels, CHANNELS.CieCnsFeq)}
                                value={CHANNELS.CieCnsFeq}
                            />
                            <SpidTypeSelectorBox
                                icon={faVideo}
                                isComingSoon={!VIDEO_ENABLED}
                                label={<FormattedMessage id="spid-recognition-method.VIDEO" />}
                                onChange={handleChange}
                                selected={isEqual(formData.channels, CHANNELS.Video)}
                                value={CHANNELS.Video}
                            />
                            <SpidTypeSelectorBox
                                icon={faUser}
                                isComingSoon={!RAO_ENABLED}
                                onChange={handleChange}
                                label={<FormattedMessage id="spid-recognition-method.RAO" />}
                                selected={isEqual(formData.channels, CHANNELS.Rao)}
                                value={CHANNELS.Rao}
                            />
                        </TypesContainer>
                        <div>
                            <H4 color="darkImperialBlue">{handleShowTypeDescription().title}</H4>
                            {handleShowTypeDescription().description}
                        </div>
                    </TypesAndDescriptionContainer>
                </>
            ) : (
                <RaoSelection />
            )}

            <Footer>
                <Button
                    kind="secondary"
                    onClick={() => {
                        showRaoSelection ? setShowRaoSelection(false) : previousStep();
                        formData.channels.rao &&
                            showRaoSelection &&
                            setFormData({
                                ...formData,
                                raoId: ""
                            });
                    }}
                >
                    <FormattedMessage id="general.back" />
                </Button>
                <Button
                    disabled={
                        isEmpty(formData.channels) ||
                        (formData.channels.video &&
                            (spidSlots.SPID_VIDEO.totalSlots === 0 || remainingVideoSlots === 0)) ||
                        (formData.channels.rao && showRaoSelection && !formData.raoId)
                    }
                    onClick={() => {
                        handleNextStep();
                        dispatchWithParentApp(resetGetRaoListStatus());
                    }}
                >
                    <FormattedMessage id="general.continue" />
                </Button>
            </Footer>
        </>
    );
};

Recognition.propTypes = {
    spidSlots: PropTypes.shape({
        SPID: PropTypes.shape({
            totalSlots: PropTypes.number,
            usedSlots: PropTypes.number,
            extraUsageAvailable: PropTypes.bool
        }),
        SPID_VIDEO: PropTypes.shape({
            totalSlots: PropTypes.number,
            usedSlots: PropTypes.number
        })
    })
};

export default Recognition;
