import { ParentApp } from "@mondora/microfrontends";
import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import Spinner from "../components/vapor-components/spinner";
import { LOCAL_DEVELOPMENT_ENABLED } from "../config";
import styled from "styled-components";
import { MockParentApp } from "../local-develop-setup";

const SpinnerContainer = styled.div`
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ParentAppContext = createContext();

export const ParentAppProvider = ({ children, isWaitingForParentApp = true }) => {
    const [parentApp, setParentApp] = useState();

    const [isWaitingForParent, setIsWaitingForParent] = useState(isWaitingForParentApp);

    const [data, setData] = useState({});

    useEffect(() => {
        const parentAppOptions = {
            onLaunch: params => {
                console.log(params);
                setData(params);
                setIsWaitingForParent(false);
            }
        };
        const parentApp = LOCAL_DEVELOPMENT_ENABLED
            ? new MockParentApp(parentAppOptions)
            : new ParentApp(parentAppOptions);

        setParentApp(parentApp);
    }, [setData, setParentApp]);

    const refreshToken = useCallback(async () => {
        const token = await parentApp.call("getNewAccessToken");
        setData({ ...data, user: { ...data.user, token } });

        return token;
    }, [data, parentApp]);

    const contextValues = useMemo(() => ({ data, methods: { refreshToken } }), [data, refreshToken]);

    return isWaitingForParent ? (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>
    ) : (
        <ParentAppContext.Provider value={{ parentApp: contextValues }}>{children}</ParentAppContext.Provider>
    );
};
