import { combineReducers } from "redux";

import { GET_SPID_LIST_ERROR, GET_SPID_LIST_START, GET_SPID_LIST_SUCCESS } from "../actions/spid";

const defaultStatus = {
    started: false,
    error: false,
    ended: false,
    errorInfo: {
        code: "",
        message: ""
    }
};

function getSpidListStatus(state = defaultStatus, { type, payload, error }) {
    switch (type) {
        case GET_SPID_LIST_START:
            return {
                ...defaultStatus,
                started: true
            };
        case GET_SPID_LIST_SUCCESS:
            return {
                ...defaultStatus,
                ended: true
            };
        case GET_SPID_LIST_ERROR:
            return {
                ...defaultStatus,
                error: true,
                errorInfo: { message: error }
            };
        default:
            return state;
    }
}

const defaultPage = { content: [], first: true, last: false };

const defaultGetSpidListContent = {
    pagination: {
        page: 0,
        pagesSize: 0
    },
    sort: {
        property: "",
        direction: ""
    },
    page: defaultPage,
    totalPages: 0
};

function getSpidListContent(state = defaultGetSpidListContent, { type, payload }) {
    switch (type) {
        case GET_SPID_LIST_SUCCESS:
            return {
                pagination: {
                    page: payload.page,
                    pagesSize: payload.size
                },
                sort: {
                    property: payload.property,
                    direction: payload.direction
                },
                page: { content: payload.content, first: payload.first, last: payload.last },
                totalPages: payload.totalPages
            };
        default:
            return state;
    }
}

export const getSpidList = combineReducers({ status: getSpidListStatus, content: getSpidListContent });
