import React, { useContext } from "react";
import PropTypes from "prop-types";

import { faBuilding, faUser } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "@ts-digital/vrc";
import { FormattedMessage, useIntl } from "react-intl";
import { Tooltip } from "@vapor/material";

import { SpidTypeSelectorBoxesContainer, Footer } from "./styled";
import { BodyCopy4, H3 } from "../../../vapor-components/typography";
import SpidTypeSelectorBox from "../../components/spid-type-selector-box";
import { CreateNewSpidContext } from "../../../../providers/create-new-spid-provider";

const Typology = ({ spidSlots }) => {
    const { formData, nextStep, previousStep, setFormData } = useContext(CreateNewSpidContext);
    const intl = useIntl();

    const remainingVideoSlots = spidSlots.SPID.totalSlots - spidSlots.SPID.usedSlots;

    const handleChange = value => {
        setFormData({
            ...formData,
            spidType: value
        });
    };

    return (
        <>
            <div data-testid="typology-container">
                <H3 color="yankeesBlue" weight="bold">
                    <FormattedMessage id="c-new-spid-modal-content.typology.title" />
                </H3>
                <BodyCopy4 color="cadet">
                    <FormattedMessage
                        id="c-new-spid-modal-content.typology.description"
                        values={{
                            b: chunks => <b>{chunks}</b>
                        }}
                    />
                </BodyCopy4>
                <SpidTypeSelectorBoxesContainer>
                    <SpidTypeSelectorBox
                        icon={faUser}
                        label={intl.formatMessage({ id: `spid-type.${formData.entity}` })}
                        onChange={handleChange}
                        selected={formData.spidType === formData.entity}
                        value={formData.entity}
                    />
                    <Tooltip
                        title={<FormattedMessage id="c-new-spid-modal-content.typology.tooltip.finished-slots" />}
                        arrow
                        disableHoverListener={remainingVideoSlots > 0}
                        disableFocusListener={remainingVideoSlots > 0}
                    >
                        <SpidTypeSelectorBox
                            finished={remainingVideoSlots === 0}
                            icon={faBuilding}
                            label={intl.formatMessage({ id: `spid-type.PROFESSIONAL_${formData.entity}` })}
                            onChange={handleChange}
                            selected={formData.spidType === `PROFESSIONAL_${formData.entity}`}
                            value={`PROFESSIONAL_${formData.entity}`}
                        />
                    </Tooltip>
                </SpidTypeSelectorBoxesContainer>
            </div>
            <Footer>
                <Button kind="secondary" onClick={() => previousStep()}>
                    <FormattedMessage id="general.back" />
                </Button>
                <Button disabled={!formData.spidType} onClick={() => nextStep()}>
                    <FormattedMessage id="general.continue" />
                </Button>
            </Footer>
        </>
    );
};

Typology.propTypes = {
    spidSlots: PropTypes.shape({
        SPID: PropTypes.shape({
            totalSlots: PropTypes.number,
            usedSlots: PropTypes.number,
            extraUsageAvailable: PropTypes.bool
        }),
        SPID_VIDEO: PropTypes.shape({
            totalSlots: PropTypes.number,
            usedSlots: PropTypes.number
        })
    })
};

export default Typology;
