import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const LoadingIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.primary.richElectricBlue};
    font-size: 3.5rem;
    color: ${props => props.colors};
`;

export const IconsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;
