import React, { useCallback, useContext, useEffect, useState } from "react";

import { faList, faSearch, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, RadioButton } from "@ts-digital/vrc";
import { Autocomplete, Box, CircularProgress, TextField } from "@vapor/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { BodyCopy, BodyCopy4, H3 } from "../../../../../vapor-components/typography";
import {
    EmptyListIconsContainer,
    EmptyMessageContainer,
    FiltersContainer,
    ListIcon,
    RadioButtonContainer,
    RaoInfoContainer,
    RaoListContainer,
    RaoListPageContainer,
    SearchContainer,
    SearchIconContainer,
    TimesIconContainer
} from "./styled";
import { CreateNewSpidContext } from "../../../../../../providers/create-new-spid-provider";
import { getRaoList, resetGetRaoListStatus } from "../../../../../../actions/rao";
import { useDispatchWithParentApp } from "../../../../../../hooks/use-dispatch-with-parent-app";
import { getProvince } from "../../../../../../actions/getProvince";
import { getCities } from "../../../../../../actions/getCities";
import Pagination from "../../../../../vapor-components/pagination";
import useCachedPaginatedCall from "../../../../../../hooks/use-cached-paginated-call";

const RaoSelection = () => {
    const dispatchWithParentApp = useDispatchWithParentApp();
    const { formData, setFormData } = useContext(CreateNewSpidContext);
    const intl = useIntl();
    const [selectedProvince, setSelectedProvince] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [searchedRao, setSearchedRao] = useState(null);
    const getRaoListStatus = useSelector(state => state.getRaoList.status);
    const getProvinceStatus = useSelector(state => state.getProvince.status);
    const getCitiesStatus = useSelector(state => state.getCities.status);
    const raoList = useSelector(state => state.getRaoList.content);
    const province = useSelector(state => state.getProvince?.payload);
    const cities = useSelector(state => state.getCities?.payload);

    useEffect(() => {
        dispatchWithParentApp(getProvince());
    }, [dispatchWithParentApp]);

    useEffect(() => {
        if (selectedProvince) {
            dispatchWithParentApp(getCities(selectedProvince));
        }
    }, [dispatchWithParentApp, selectedProvince]);

    const handleChangeProvince = value => {
        setSelectedProvince(value?.code);
    };

    const handleChangeCity = value => {
        setSelectedCity(value?.code);
    };

    const handleSelectRao = value => {
        setFormData({
            ...formData,
            raoId: value
        });
    };

    useEffect(() => {
        return () => dispatchWithParentApp(resetGetRaoListStatus());
    }, [dispatchWithParentApp]);

    const callWrapper = useCallback(
        ({ name, province, city }, page) => {
            if (name || province || city) {
                dispatchWithParentApp(getRaoList(name, province, city, page));
            }
        },
        [dispatchWithParentApp]
    );

    const { currentPage, pages, setPage, updateFilters } = useCachedPaginatedCall(callWrapper, raoList.page, {}, true);

    const currentRaoList = pages[currentPage] || {};
    const currentRaoListPage = currentRaoList.content;

    return (
        <>
            <H3 color="yankeesBlue" weight="bold">
                <FormattedMessage id="c-new-spid-modal-content.recognition.rao-selection.title" />
            </H3>
            <SearchContainer>
                <FiltersContainer>
                    <TextField
                        onChange={e => setSearchedRao(e.target.value)}
                        placeholder={intl.formatMessage({
                            id: "c-new-spid-modal-content.rao-selection.insert-name.placeholder"
                        })}
                        label={<FormattedMessage id="c-new-spid-modal-content.rao-selection.insert-name" />}
                    />
                    <Autocomplete
                        autoComplete
                        autoHighlight
                        disabled={getProvinceStatus.started}
                        options={!province ? [] : province}
                        isOptionEqualToValue={(option, value) => option.code === value.code}
                        getOptionLabel={option => option.name}
                        loading={getProvinceStatus.started}
                        onChange={(event, value) => handleChangeProvince(value)}
                        sx={{ width: "100%" }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                placeholder={intl.formatMessage({
                                    id: "c-new-spid-modal-content.rao-selection.select-province.placeholder"
                                })}
                                label={<FormattedMessage id="c-new-spid-modal-content.rao-selection.select-province" />}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {params.InputProps.endAdornment}
                                            {getProvinceStatus.started ? <CircularProgress size={20} /> : null}
                                        </React.Fragment>
                                    )
                                }}
                            />
                        )}
                    />
                    <Autocomplete
                        autoComplete
                        autoHighlight
                        disabled={getCitiesStatus.started || !selectedProvince}
                        options={!cities ? [] : cities}
                        isOptionEqualToValue={(option, value) => option.code === value.code}
                        getOptionLabel={option => option.name}
                        loading={getCitiesStatus.started}
                        onChange={(event, value) => handleChangeCity(value)}
                        sx={{ width: "100%" }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                placeholder={intl.formatMessage({
                                    id: "c-new-spid-modal-content.rao-selection.select-city.placeholder"
                                })}
                                label={<FormattedMessage id="c-new-spid-modal-content.rao-selection.select-city" />}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {params.InputProps.endAdornment}
                                            {getCitiesStatus.started ? <CircularProgress size={20} /> : null}
                                        </React.Fragment>
                                    )
                                }}
                            />
                        )}
                    />

                    <Button
                        disabled={(!searchedRao && !selectedProvince) || getRaoListStatus.started}
                        onClick={() =>
                            updateFilters({
                                name: searchedRao,
                                province: selectedProvince,
                                city: selectedCity,
                                pages
                            })
                        }
                    >
                        <FormattedMessage id="general.search" />
                    </Button>
                </FiltersContainer>
                {getRaoListStatus.ended && currentRaoListPage?.length > 0 && (
                    <RaoListContainer>
                        <BodyCopy4 weight="500">
                            {raoList.totalElements} <FormattedMessage id="general.results-found" />
                        </BodyCopy4>
                        <RaoListPageContainer>
                            {currentRaoListPage.map((r, key) => (
                                <RadioButtonContainer
                                    onClick={() => handleSelectRao(r.oneplatform_id)}
                                    key={`rao_${key}`}
                                >
                                    <RadioButton
                                        checked={formData.raoId === r.oneplatform_id}
                                        onChange={() => handleSelectRao(r.oneplatform_id)}
                                        value={r.oneplatform_id}
                                    />
                                    <RaoInfoContainer>
                                        <BodyCopy4 color="yankeesBlue" weight="500">
                                            {r.name}
                                        </BodyCopy4>
                                        <BodyCopy4 color="yankeesBlue">
                                            {r.address} {r.city_name} ({r.province}) {r.postal_code}
                                        </BodyCopy4>
                                    </RaoInfoContainer>
                                </RadioButtonContainer>
                            ))}
                        </RaoListPageContainer>
                        <Pagination
                            currentPage={currentPage}
                            isFirstPage={currentRaoList.first}
                            isLastPage={currentRaoList.last}
                            isLoading={getRaoListStatus.started}
                            onChangePage={setPage}
                            onNextPage={() => setPage(currentPage + 1)}
                            onPreviousPage={() => setPage(currentPage - 1)}
                            totalPages={raoList.totalPages}
                        />
                    </RaoListContainer>
                )}
                {!getRaoListStatus.ended && !getRaoListStatus.started && (
                    <EmptyMessageContainer>
                        <SearchIconContainer>
                            <FontAwesomeIcon icon={faSearch} />
                        </SearchIconContainer>
                        <H3 weight="bold" color="yankeesBlue">
                            <FormattedMessage id="c-new-spid-modal-content.rao-selection.search-rao" />
                        </H3>
                        <BodyCopy color="yankeesBlue">
                            <FormattedMessage id="c-new-spid-modal-content.rao-selection.search-rao.description" />
                        </BodyCopy>
                    </EmptyMessageContainer>
                )}
                {getRaoListStatus.ended && currentRaoListPage?.length === 0 && (
                    <EmptyMessageContainer>
                        <EmptyListIconsContainer>
                            <ListIcon icon={faList} />
                            <TimesIconContainer>
                                <FontAwesomeIcon icon={faTimes} />
                            </TimesIconContainer>
                        </EmptyListIconsContainer>
                        <H3 weight="bold" color="yankeesBlue">
                            <FormattedMessage id="c-new-spid-modal-content.rao-selection.empty-list.title" />
                        </H3>
                        <BodyCopy color="yankeesBlue">
                            <FormattedMessage id="c-new-spid-modal-content.rao-selection.empty-list.description" />
                        </BodyCopy>
                    </EmptyMessageContainer>
                )}
                {getRaoListStatus.started && (
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
            </SearchContainer>
        </>
    );
};

export default RaoSelection;
