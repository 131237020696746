import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { IconsContainer, LoadingIcon } from "./styled";

const Spinner = () => {
    return (
        <IconsContainer>
            <LoadingIcon icon={faCircleNotch} className="fa-spin" />
        </IconsContainer>
    );
};

export default Spinner;
