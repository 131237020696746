import styled from "styled-components";

export const FormContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2.5rem;
    margin-top: 2rem;
    text-align: left;
`;

export const Footer = styled.div`
    background-color: ${props => props.theme.colors.background.whiteSmoke};
    padding: 0.75rem 0.5rem;
    display: flex;
    justify-content: flex-end;
    gap: 1.5rem;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
`;

export const EmailAlertContainer = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 0.5rem;
    line-height: 0.75rem;
`;
