import styled from "styled-components";

const color = {
    informative: props => props.theme.colors.primary.darkImperialBlue,
    check: props => props.theme.colors.status.laSalleGreen,
    warning: props => props.theme.colors.status.crayola,
    error: props => props.theme.colors.status.maximumRed
};

export const Container = styled.div`
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 4px;
    border-left-width: 1px;
    border-color: ${props => color[props.variant]};
    border-style: solid;
    background-color: white;
    display: grid;
    grid-template-columns: 64px 1fr 64px;
    min-width: 458px;
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: ${props => color[props.variant]};
    height: 64px;
`;

export const MessageContainer = styled.div`
    padding-top: 18px;
    padding-bottom: 18px;
`;

export const CloseButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: ${props => props.theme.colors.text.yankeesBlue};
    height: 64px;
    cursor: pointer;

    &:hover {
        color: ${props => props.theme.colors.primary.richElectricBlue};
    }
`;
