import axios from "axios";
import cuid from "cuid";
import flatten from "flat";
import { v4 as uuid } from "uuid";

async function performCall(url, method, urlParams, body, parentApp) {
    let queryString = getQueryString(urlParams);

    queryString = queryString !== "" ? `?${queryString}` : "";

    const headers = {
        Authorization: `Bearer ${parentApp.data.user.token ? parentApp.data.user.token : ""}`,
        "Content-Type": "application/json",
        "X-App-Name": "Spid",
        "X-App-Version": "1.0",
        "X-Request-Id": cuid(),
        "X-Correlation-Id": uuid()
    };

    if (parentApp.data.user.id) {
        headers["X-User-Id"] = parentApp.data.user.id;
    }

    if (parentApp.data.item.id) {
        headers["X-Item-Id"] = parentApp.data.item.id;
    }

    if (parentApp.data.settings.language) {
        headers["Accept-Language"] = parentApp.data.settings.language;
    }

    try {
        const response = await axios({
            url: `${url}${queryString}`,
            method: method,
            data: body && JSON.stringify(body),
            headers
        });

        return { ...response, ok: true };
    } catch (error) {
        return { ...error.response, ok: false };
    }
}

export async function restApiCall(url, method, urlParams, body, parentApp, codes = ["401"]) {
    let response = await performCall(url, method, urlParams, body, parentApp);

    if (!response.ok) {
        if (codes.includes(response.status)) {
            let token = "";

            try {
                token = await parentApp.methods.refreshToken();
            } catch (e) {
                const errResponse = { status: response.status, ...response.data };
                throw errResponse;
            }

            response = await performCall(url, method, urlParams, body, {
                ...parentApp,
                user: { ...parentApp.user, token }
            });

            if (!response.ok) {
                const errResponse = {
                    status: response.status,
                    ...response.data
                };
                throw errResponse;
            }
        } else {
            const errResponse = { status: response.status, ...response.data };
            throw errResponse;
        }
    }

    return response.data;
}

export function headRestCall(url, urlParams, parentApp) {
    return restApiCall(url, "HEAD", urlParams, null, parentApp);
}

export function getRestCall(url, urlParams, parentApp) {
    return restApiCall(url, "GET", urlParams, null, parentApp);
}

export function postRestCall(url, urlParams, body, parentApp) {
    return restApiCall(url, "POST", urlParams, body, parentApp);
}

export function deleteRestCall(url, urlParams, body, parentApp) {
    return restApiCall(url, "DELETE", urlParams, body, parentApp);
}

export function patchRestCall(url, urlParams, body, parentApp) {
    return restApiCall(url, "PATCH", urlParams, body, parentApp);
}

export function putRestCall(url, urlParams, body, parentApp) {
    return restApiCall(url, "PUT", urlParams, body, parentApp);
}

/**
 * Transform a nested object in a query string
 * @param params nested object
 */
function getQueryString(params) {
    return params
        ? Object.entries(flatten(params, { safe: true }))
              .filter(([_, value]) => value !== undefined && value !== null)
              .map(key => `${key[0]}=${encodeURIComponent(key[1] + "")}`)
              .join("&")
        : "";
}
