import React, { useContext } from "react";
import PropTypes from "prop-types";

import { faBuilding, faUser } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "@ts-digital/vrc";
import { FormattedMessage, useIntl } from "react-intl";

import { SPID_TYPES } from "../../../../utils/constants";
import { BodyCopy4, H3 } from "../../../vapor-components/typography";
import SpidTypeSelectorBox from "../../components/spid-type-selector-box";
import { CreateNewSpidContext } from "../../../../providers/create-new-spid-provider";
import { Footer, SpidTypeSelectorBoxesContainer } from "./styled";

const Entity = ({ onClose }) => {
    const { formData, nextStep, setFormData } = useContext(CreateNewSpidContext);
    const intl = useIntl();

    const handleChange = value => {
        setFormData({
            ...formData,
            entity: value,
            spidType: ""
        });
    };

    return (
        <>
            <div data-testid="entity-container">
                <H3 color="yankeesBlue" weight="bold">
                    <FormattedMessage id="c-new-spid-modal-content.entity.title" />
                </H3>
                <BodyCopy4 color="cadet">
                    <FormattedMessage
                        id="c-new-spid-modal-content.entity.description"
                        values={{
                            b: chunks => <b>{chunks}</b>
                        }}
                    />
                </BodyCopy4>
                <SpidTypeSelectorBoxesContainer>
                    <SpidTypeSelectorBox
                        icon={faUser}
                        label={intl.formatMessage({ id: "c-new-spid-modal-content.entity.private-or-professional" })}
                        onChange={handleChange}
                        selected={formData.entity === SPID_TYPES.Individual}
                        value={SPID_TYPES.Individual}
                    />
                    <SpidTypeSelectorBox
                        icon={faBuilding}
                        label={intl.formatMessage({ id: "c-new-spid-modal-content.entity.company" })}
                        // TODO riutilizzare la funzione handleChange quando la tipologia sarà gestita e rimuovere il flag isComingSoon
                        // onChange={handleChange}
                        onChange={() => {}}
                        selected={formData.entity === SPID_TYPES.LegalEntity}
                        value={SPID_TYPES.LegalEntity}
                        isComingSoon={true}
                    />
                </SpidTypeSelectorBoxesContainer>
            </div>
            <Footer>
                <Button data-testid="close-button" kind="secondary" onClick={onClose}>
                    <FormattedMessage id="general.cancel" />
                </Button>
                <Button disabled={!formData.entity} onClick={() => nextStep()}>
                    <FormattedMessage id="general.continue" />
                </Button>
            </Footer>
        </>
    );
};

Entity.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default Entity;
