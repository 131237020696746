import { API_READ_URL } from "../config";
import { getRestCall } from "../utils/rest-api-call";

export const GET_RAO_BY_ID_START = "GET_RAO_BY_ID_START";
export const GET_RAO_BY_ID_ERROR = "GET_RAO_BY_ID_ERROR";
export const GET_RAO_BY_ID_SUCCESS = "GET_RAO_BY_ID_SUCCESS";
export const GET_RAO_BY_ID_RESET = "GET_RAO_BY_ID_RESET";

export function getRaoById(raoId) {
    return async (dispatch, parentApp) => {
        dispatch({
            type: GET_RAO_BY_ID_START,
            payload: {
                raoId
            }
        });

        try {
            const response = await getRestCall(
                `${API_READ_URL}/api/v1/spid/rao`,
                {
                    raoId: raoId
                },
                parentApp
            );
            dispatch({
                type: GET_RAO_BY_ID_SUCCESS,
                payload: response
            });
        } catch (error) {
            dispatch({
                type: GET_RAO_BY_ID_ERROR,
                error: error
            });
        }
    };
}

export function resetGetRaoByIdStatus() {
    return dispatch => {
        dispatch({
            type: GET_RAO_BY_ID_RESET
        });
    };
}
