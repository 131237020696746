import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle, faExclamationCircle, faInfoCircle, faMinusCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BodyCopy } from "../typography";
import { CloseButtonContainer, Container, IconContainer, MessageContainer } from "./styled";
import { useToast } from "../../../providers/toast-provider";

const TimedToastNotification = ({ id, message, variant }) => {
    const icon = {
        informative: faInfoCircle,
        check: faCheckCircle,
        warning: faExclamationCircle,
        error: faMinusCircle
    };

    const { removeToast } = useToast();

    useEffect(() => {
        const timer = setTimeout(() => {
            removeToast(id);
        }, 5000);

        return () => {
            clearTimeout(timer);
        };
    }, [id, removeToast]);

    return (
        <Container variant={variant} data-testid="toast-notification">
            <IconContainer variant={variant}>
                <FontAwesomeIcon icon={icon[variant]} />
            </IconContainer>
            <MessageContainer>
                <BodyCopy weight="500">{message}</BodyCopy>
            </MessageContainer>
            <CloseButtonContainer onClick={() => removeToast(id)}>
                <FontAwesomeIcon icon={faTimes} />
            </CloseButtonContainer>
        </Container>
    );
};

TimedToastNotification.defaultProps = {
    variant: "informative"
};

TimedToastNotification.propTypes = {
    id: PropTypes.number,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    variant: PropTypes.oneOf(["informative", "check", "warning", "error"])
};

export default TimedToastNotification;
