import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@ts-digital/vrc";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";

import { BackButtonTitle, Container, TitleContainer } from "./styled";
import { Descriptive, H4 } from "../typography";

const PageTitle = ({ backButtonTitle, onBack, subtitle, title }) => {
    return (
        <Container>
            <div>
                {onBack && (
                    <Button kind={"tertiary"} onClick={onBack}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                        <BackButtonTitle>{backButtonTitle}</BackButtonTitle>
                    </Button>
                )}
            </div>
            <TitleContainer>
                <H4 color="darkImperialBlue">{title}</H4>
                <Descriptive color="cadet">{subtitle}</Descriptive>
            </TitleContainer>
            <div></div>
        </Container>
    );
};

PageTitle.propTypes = {
    backButtonTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    title: PropTypes.any.isRequired,
    subtitle: PropTypes.any,
    onBack: PropTypes.func
};

export default PageTitle;
