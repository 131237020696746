import { API_WRITE_URL } from "../config";
import { postRestCall } from "../utils/rest-api-call";

export const SEND_SESSION_LINK_START = "SEND_SESSION_LINK_START";
export const SEND_SESSION_LINK_ERROR = "SEND_SESSION_LINK_ERROR";
export const SEND_SESSION_LINK_SUCCESS = "SEND_SESSION_LINK_SUCCESS";

export function sendSessionLink(spidId) {
    return async (dispatch, parentApp) => {
        dispatch({
            type: SEND_SESSION_LINK_START
        });

        try {
            const response = await postRestCall(
                `${API_WRITE_URL}/api/v1/spid/${spidId}/sendSessionLink`,
                {},
                {},
                parentApp
            );
            dispatch({
                type: SEND_SESSION_LINK_SUCCESS,
                payload: { ...response }
            });
        } catch (error) {
            dispatch({
                type: SEND_SESSION_LINK_ERROR,
                error: error
            });
        }
    };
}
