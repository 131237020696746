const Individual = "INDIVIDUAL";
const LegalEntity = "LEGAL_ENTITY";
const ProfessionalIndividual = "PROFESSIONAL_INDIVIDUAL";
const ProfessionalLegalEntity = "PROFESSIONAL_LEGAL_ENTITY";

export const SPID_TYPES = { Individual, LegalEntity, ProfessionalIndividual, ProfessionalLegalEntity };

const Approved = "APPROVED";
const Created = "CREATED";
const Deleted = "DELETED";
const Expired = "EXPIRED";
const Hold = "HOLD";
const Initialized = "INITIALIZED";
const Issued = "ISSUED";
const Issuing = "ISSUING";
const Revoked = "REVOKED";
const SessionExpired = "SESSION_EXPIRED";

export const SPID_STATUS = {
    Approved,
    Created,
    Deleted,
    Expired,
    Hold,
    Initialized,
    Issued,
    Issuing,
    Revoked,
    SessionExpired
};

const Ascending = "ASC";
const Descending = "DESC";

export const SORT_DIRECTIONS = { Ascending, Descending };

const InitialChannels = {
    cie: false,
    cns: false,
    feq: false,
    video: false,
    rao: false
};

const CieCnsFeq = {
    ...InitialChannels,
    cns: true,
    feq: true
};

const Rao = {
    ...InitialChannels,
    rao: true
};

const Video = {
    ...InitialChannels,
    video: true
};
export const CHANNELS = { InitialChannels, CieCnsFeq, Video, Rao };
