import React, { createContext, useCallback, useEffect, useState } from "react";

import { API_URL, ENV } from "../../config";
import { getRestCall } from "../../utils/rest-api-call";

export const FeatureContext = createContext({});

export const FeatureContextProvider = ({ children }) => {
    const [features, setFeatures] = useState({});

    useEffect(() => {
        async function fetchFeatures() {
            try {
                const features = await getRestCall(`${API_URL}/features`);
                setFeatures(features);
            } catch (e) {
                console.error(e);
            }
        }

        fetchFeatures();
    }, []);

    const toggleFeature = useCallback(
        featureName => {
            if (ENV !== "dev") {
                console.error("Manual toggle of features is not allowed outside of dev.");
                return;
            }

            setFeatures({ ...features, [featureName]: !features[featureName] });
        },
        [features]
    );

    useEffect(() => {
        if (window.__MINGO_FEATURE_HOOK__) {
            window.__MINGO_FEATURE_HOOK__(features, updatedFeatures => {
                setFeatures({ ...features, ...updatedFeatures });
            });
        }
    }, [features, toggleFeature]);

    return <FeatureContext.Provider value={{ features, toggleFeature }}>{children}</FeatureContext.Provider>;
};
