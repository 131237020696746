import styled from "styled-components";

const color = {
    cadet: props => props.theme.colors.text.cadet,
    columbiaBlue: props => props.theme.colors.primary.columbiaBlue,
    darkImperialBlue: props => props.theme.colors.primary.darkImperialBlue,
    quickSilver: props => props.theme.colors.text.quickSilver,
    richElectricBlue: props => props.theme.colors.primary.richElectricBlue,
    yankeesBlue: props => props.theme.colors.text.yankeesBlue
};

export const H2 = styled.h2`
    color: ${props => color[props.color]};
    font-size: 2.125rem;
    font-family: Cairo;
    font-weight: ${props => (props.weight ? props.weight : 600)};
    margin: 0;
`;

export const H3 = styled.h3`
    color: ${props => color[props.color]};
    font-size: 1.5rem;
    font-family: Cairo;
    font-weight: ${props => (props.weight ? props.weight : 600)};
    letter-spacing: 0.18px;
    margin: 0;
`;

export const H4 = styled.h4`
    color: ${props => color[props.color]};
    font-size: 1.25rem;
    font-family: Cairo;
    font-weight: ${props => (props.weight ? props.weight : "normal")};
    letter-spacing: 0.15px;
    margin: 0;
`;

export const H5 = styled.h5`
    color: ${props => color[props.color]};
    font-size: 1rem;
    font-family: Cairo;
    font-weight: ${props => (props.weight ? props.weight : 600)};
    letter-spacing: 0.12px;
    margin: 0;
`;

export const Descriptive = styled.div`
    color: ${props => color[props.color]};
    font-size: 0.75rem;
    font-family: Roboto;
    font-weight: ${props => (props.weight ? props.weight : "normal")};
    letter-spacing: 0.4px;
    margin: 0;
`;

export const BodyCopy = styled.div`
    color: ${props => color[props.color]};
    font-size: 1rem;
    font-family: Roboto;
    font-weight: ${props => (props.weight ? props.weight : "normal")};
    letter-spacing: 0.44px;
    margin: 0;
`;

export const BodyCopy4 = styled.div`
    color: ${props => color[props.color]};
    font-size: 0.875rem;
    font-family: Roboto;
    font-weight: ${props => (props.weight ? props.weight : "normal")};
    letter-spacing: 0.25px;
    margin: 0;
`;

export const SubTitleRegular = styled.div`
    color: ${props => color[props.color]};
    font-size: 1rem;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.11px;
    margin: 0;
`;

export const SubHeading = styled.div`
    color: ${props => color[props.color]};
    font-size: 0.875rem;
    font-family: Cairo;
    font-weight: ${props => (props.weight ? props.weight : 600)};
    letter-spacing: 0.1px;
    margin: 0;
`;
