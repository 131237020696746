import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";

export const StyledTable = styled.table`
    border-collapse: collapse;
    width: 100%;
    background-color: ${props => props.theme.colors.background.white};
`;

export const THead = styled.thead`
    text-align: left;
`;

export const FlattenedTHead = styled.thead`
    text-align: left;
`;

export const Td = styled.td`
    color: ${props => props.theme.colors.text.yankeesBlue};
    border-bottom: 1px solid ${props => props.theme.colors.text.quickSilver};
    padding: 0.5rem;
    font-size: 0.875rem;
    font-family: Roboto;
    font-weight: normal;
    height: 3rem;
    box-sizing: border-box;
    text-align: ${props => (props.centeredText ? "center" : "left")};
    line-height: 1rem;
`;

export const Th = styled.th`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 0.875rem;
    font-family: Roboto;
    font-weight: 500;
    padding: 1rem 0.5rem;
    text-transform: capitalize;
`;

export const ActionIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.primary.richElectricBlue};
    text-align: center;
    cursor: pointer;

    :hover {
        color: ${props => props.theme.colors.primary.darkImperialBlue};
    }

    ${props =>
        props.disabled &&
        css`
            color: ${props => props.theme.colors.primary.columbiaBlue};
            cursor: not-allowed;
            pointer-events: none;
        `}
`;

export const DangerActionIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.status.maximumRed};
    text-align: center;
    cursor: pointer;

    :hover {
        color: ${props => props.theme.colors.accent.prune};
    }

    ${props =>
        props.disabled &&
        css`
            color: ${props => props.theme.colors.primary.columbiaBlue};
            cursor: not-allowed;
            pointer-events: none;
        `}
`;

export const DisabledActionIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.primary.columbiaBlue};
    text-align: center;
`;

export const LoadingActionIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.primary.richElectricBlue};
    text-align: center;
`;

export const ArrowsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

export const LoadingIcon = styled.div`
    width: 100%;
    height: 484px;
    color: ${props => props.theme.colors.primary.richElectricBlue};
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;
