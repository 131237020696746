import React from "react";
import PropTypes from "prop-types";

import TimedToastNotification from "../vapor-components/timed-toast-notification";
import { Wrapper } from "./styled";

const ToastContainer = ({ toasts }) => {
    return (
        <Wrapper>
            {toasts.map((toast, key) => (
                <TimedToastNotification
                    key={`toast-notification-${toast.variant}-${key}`}
                    id={toast.id}
                    message={toast.message}
                    variant={toast.variant}
                />
            ))}
        </Wrapper>
    );
};

ToastContainer.propTypes = {
    toasts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            variant: PropTypes.oneOf(["informative", "check", "warning", "error"])
        })
    )
};

export default ToastContainer;
