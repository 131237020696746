import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { faCircleNotch, faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input } from "@ts-digital/vrc";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { OtherSharingOptionsContainer, SessionLinkContainer } from "./styled";
import { BodyCopy4 } from "../vapor-components/typography";
import { useDispatchWithParentApp } from "../../hooks/use-dispatch-with-parent-app";
import { sendSessionLink } from "../../actions/sendSessionLink";
import { useToast } from "../../providers/toast-provider";

const ShareSpidRequestModalContent = ({ shareSpidRequestModalValues }) => {
    const intl = useIntl();
    const dispatchWithParentApp = useDispatchWithParentApp();
    const { addToast } = useToast();
    const sendSessionLinkStatus = useSelector(state => state.sendSessionLink.status);

    useEffect(() => {
        sendSessionLinkStatus.ended &&
            addToast(
                intl.formatMessage({
                    id: "c-share-spid-request-modal-content.send-session-link.success"
                }),
                "check"
            );
        sendSessionLinkStatus.error &&
            addToast(
                intl.formatMessage({
                    id: "c-share-spid-request-modal-content.send-session-link.error"
                }),
                "error"
            );
    }, [addToast, intl, sendSessionLinkStatus]);

    return (
        <div>
            <SessionLinkContainer>
                <Input
                    label={intl.formatMessage(
                        {
                            id: "c-share-spid-request-modal-content.session-link-input.label"
                        },
                        {
                            name: shareSpidRequestModalValues.name,
                            surname: shareSpidRequestModalValues.surname
                        }
                    )}
                    value={shareSpidRequestModalValues.sessionLink}
                    onChange={() => {}}
                />
            </SessionLinkContainer>
            <OtherSharingOptionsContainer>
                <BodyCopy4 color="yankeesBlue" weight="500">
                    <FormattedMessage id="c-share-spid-request-modal-content.other-sharing-options" />
                </BodyCopy4>
                <div>
                    <Button
                        disabled={sendSessionLinkStatus.started}
                        kind={"tertiary"}
                        onClick={() => dispatchWithParentApp(sendSessionLink(shareSpidRequestModalValues.id))}
                    >
                        {sendSessionLinkStatus.started ? (
                            <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
                        ) : (
                            <FontAwesomeIcon icon={faEnvelope} />
                        )}
                    </Button>
                </div>
            </OtherSharingOptionsContainer>
        </div>
    );
};

ShareSpidRequestModalContent.propTypes = {
    shareSpidRequestModalValues: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        surname: PropTypes.string,
        sessionLink: PropTypes.string
    })
};

export default ShareSpidRequestModalContent;
