import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage, useIntl } from "react-intl";

import { BodyCopy } from "../vapor-components/typography";

const RefreshSpidRequestModalContent = ({ selectedSpidRequestToRefresh }) => {
    const intl = useIntl();

    const today = new Date();
    const tomorrow = today.setDate(today.getDate() + 3);

    const expirationDate = intl.formatDate(tomorrow, {
        year: "numeric",
        month: "numeric",
        day: "numeric"
    });

    return (
        <div>
            <BodyCopy>
                <FormattedMessage
                    id="c-refresh-spid-request-modal-content.update-request-for-another-3-days"
                    values={{
                        b: chunks => <b>{chunks}</b>,
                        name: selectedSpidRequestToRefresh.name,
                        surname: selectedSpidRequestToRefresh.surname
                    }}
                />
            </BodyCopy>
            <br />
            <BodyCopy>
                <FormattedMessage
                    id="c-refresh-spid-request-modal-content.send-new-link-to"
                    values={{
                        b: chunks => <b>{chunks}</b>,
                        email: selectedSpidRequestToRefresh.email,
                        date: expirationDate
                    }}
                />
            </BodyCopy>
        </div>
    );
};

RefreshSpidRequestModalContent.propTypes = {
    selectedSpidRequestToRefresh: PropTypes.shape({
        name: PropTypes.string,
        surname: PropTypes.string,
        email: PropTypes.string
    })
};

export default RefreshSpidRequestModalContent;
