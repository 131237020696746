export const DOMAIN = process.env.REACT_APP_DOMAIN || "-dev.agyo.io";
export const ENV = process.env.REACT_APP_ENV || "dev";

let enableLogs = true;
if (process.env.REACT_APP_ENABLE_CONSOLE_LOGS === "false") {
    enableLogs = false;
}

export const ENABLE_CONSOLE_LOGS = enableLogs;
export const API_URL = process.env.REACT_APP_API_URL || "http://localhost";
export const API_WRITE_URL = process.env.REACT_APP_API_WRITE_URL || "http://localhost";
export const API_READ_URL = process.env.REACT_APP_API_READ_URL || "http://localhost";
export const VIDEO_ENABLED = process.env.REACT_APP_VIDEO_ENABLED === "true";
export const RAO_ENABLED = process.env.REACT_APP_RAO_ENABLED === "true";
export const LOCAL_DEVELOPMENT_ENABLED = process.env.REACT_APP_LOCAL_DEVELOPMENT_ENABLED === 'true';

export const PUBLIC_URL = process.env.PUBLIC_URL || "";

export const TS_DIGITAL_IMAGES =
    process.env.REACT_APP_TS_DIGITAL_IMAGES || "https://appdev.teamsystemdigital.com/images/ts-digital/";
