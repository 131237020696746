import { API_READ_URL } from "../config";
import { getRestCall } from "../utils/rest-api-call";

export const GET_SPID_SLOTS_START = "GET_SPID_SLOTS_START";
export const GET_SPID_SLOTS_ERROR = "GET_SPID_SLOTS_ERROR";
export const GET_SPID_SLOTS_SUCCESS = "GET_SPID_SLOTS_SUCCESS";

export function getSpidSlots() {
    return async (dispatch, parentApp) => {
        dispatch({
            type: GET_SPID_SLOTS_START
        });

        try {
            const response = await getRestCall(
                `${API_READ_URL}/api/v1/usage/${parentApp.data.item.id}/slots`,
                {},
                parentApp
            );

            dispatch({
                type: GET_SPID_SLOTS_SUCCESS,
                payload: response
            });
        } catch (error) {
            dispatch({
                type: GET_SPID_SLOTS_ERROR,
                error: error
            });
        }
    };
}
