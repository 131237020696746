import styled, { css } from "styled-components";

export const Container = styled.div`
    background-color: ${props => props.theme.colors.background.white};
    border: 1px solid transparent;
    padding-top: 1rem;
    padding-bottom: 1rem;
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
    display: grid;
    grid-template-columns: 4rem 1fr 4rem;
    border-top-width: 1px;
    border-right-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 3px;
    position: relative;

    ${props =>
        props.selected &&
        css`
            border-top-width: 1px;
            border-right-width: 1px;
            border-left-width: 1px;
            border-bottom-width: 3px;
            border-style: solid;
            border-image-slice: 1;
            border-image-source: linear-gradient(
                -26.56505117707799deg,
                ${props => props.theme.colors.primary.cyanProgress} 0%,
                ${props => props.theme.colors.primary.richElectricBlue} 100%
            );
            box-shadow: 0px 8px 24px 0px rgba(21, 41, 53, 0.32); ;
        `}
`;

export const LabelContainer = styled.div`
    justify-self: flex-start;
`;

export const RadioButtonContainer = styled.div`
    margin-top: -8px;
    justify-self: center;
`;

export const Icon = styled.div`
    color: ${props =>
        props.disabled ? props.theme.colors.primary.columbiaBlue : props.theme.colors.primary.darkImperialBlue};
    font-size: 1.5rem;
    font-size: 1.5rem;
    align-self: center;
    justify-self: center;
`;

export const ComingSoonContainer = styled.div`
    position: absolute;
    right: 5rem;
    top: 1.5rem;
    border-radius: 0.25rem;
    background-color: ${props => props.theme.colors.primary.columbiaBlue};
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-family: Cairo;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.5rem;
`;
