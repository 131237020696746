import { GET_SPID_SLOTS_ERROR, GET_SPID_SLOTS_START, GET_SPID_SLOTS_SUCCESS } from "../actions/spidslots";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export function getSpidSlots(state = defaultState, { type, payload, error }) {
    switch (type) {
        case GET_SPID_SLOTS_START:
            return {
                ...defaultState,
                status: {
                    started: true,
                    error: false,
                    ended: false
                }
            };
        case GET_SPID_SLOTS_SUCCESS:
            return {
                status: {
                    started: false,
                    error: false,
                    ended: true
                },
                spidSlots: payload
            };
        case GET_SPID_SLOTS_ERROR:
            return {
                status: {
                    started: false,
                    error: true,
                    ended: false,
                    errorInfo: { message: error }
                }
            };
        default:
            return state;
    }
}
