import { deleteSpidRequest } from "./deleteSpidRequest";
import { getCities } from "./getCities";
import { getProvince } from "./getProvince";
import { getRaoList } from "./rao";
import { getRaoById } from "./getRaoById";
import { getSpidList } from "./spid";
import { getSpidSlots } from "./spidslots";
import { initSpid } from "./initspid";
import { refreshSession } from "./refreshSession";
import { sendSessionLink } from "./sendSessionLink";

const reducersSchema = {
    deleteSpidRequest,
    getCities,
    getProvince,
    getRaoById,
    getRaoList,
    getSpidList,
    getSpidSlots,
    initSpid,
    refreshSession,
    sendSessionLink
};

export default reducersSchema;
