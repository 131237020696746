import React, { useCallback, useEffect, useState } from "react";

import { faCircleNotch, faSyncAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@ts-digital/vrc";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { deleteSpidRequest, resetDeleteSpidRequestStatus } from "../../actions/deleteSpidRequest";
import { resetGetRaoByIdStatus } from "../../actions/getRaoById";
import { resetInitSpidStatus } from "../../actions/initspid";
import { refreshSession, resetRefreshSessionStatus } from "../../actions/refreshSession";
import { getSpidList } from "../../actions/spid";
import { getSpidSlots } from "../../actions/spidslots";
import CreateNewSpidModalContent from "../../components/create-new-spid-modal-content";
import DeleteSpidRequestModalContent from "../../components/delete-spid-request-modal-content";
import DetailsDrawer from "../../components/details-drawer";
import ModifySpidRequestDrawer from "../../components/modify-spid-request-drawer";
import RefreshSpidRequestModalContent from "../../components/refresh-spid-request-modal-content";
import ShareSpidRequestModalContent from "../../components/share-spid-request-modal-content";
import SpidConsumingChart from "../../components/spid-consuming-chart";
import SpidListTable from "../../components/spid-list-table";
import { RefreshSpidRequestButtonContainer } from "../../components/spid-list-table/styled";
import Modal from "../../components/vapor-components/modal";
import PageTitle from "../../components/vapor-components/page-title";
import { BodyCopy, H3 } from "../../components/vapor-components/typography";
import VideoConsumingChart from "../../components/video-consuming-chart";
import useCachedPaginatedCall from "../../hooks/use-cached-paginated-call";
import { useDispatchWithParentApp } from "../../hooks/use-dispatch-with-parent-app";
import human from "../../images/human.svg";
import { useToast } from "../../providers/toast-provider";
import { SORT_DIRECTIONS } from "../../utils/constants";
import {
    Container,
    ContentWrapper,
    FaqContainer,
    FaqInfoBoxWrapper,
    InfoBox,
    InfoContainer,
    ModalFooterButtonsContainer,
    SmallDevicesInfoBoxWrapper,
    StyledA,
    TableActionsContainer,
    TableContainer,
    TableSectionTitleContainer
} from "./styled";
import RaoSpidRequestDialog from "../../components/rao-spid-request-dialog";

const SpidList = () => {
    const dispatchWithParentApp = useDispatchWithParentApp();
    const intl = useIntl();
    const { addToast } = useToast();

    const [isModifySpidRequestModalOpen, setIsModifySpidRequestDrawerOpen] = useState(false);
    const [selectedSpidRequestToEdit, setSelectedSpidRequestToEdit] = useState(null);
    const [selectedSpidRequestToSeeDetails, setSelectedSpidRequestToSeeDetails] = useState(null);
    const [selectedSpidRequestToDelete, setSelectedSpidRequestToDelete] = useState(null);
    const [selectedSpidRequestToRefresh, setSelectedSpidRequestToRefresh] = useState(null);
    const [isCreateNewSpidModalOpen, setIsCreateNewSpidModalOpen] = useState(false);
    const [isDeleteSpidRequestModalOpen, setIsDeleteSpidRequestModalOpen] = useState(false);
    const [isShareSpidRequestModalOpen, setIsShareSpidRequestModalOpen] = useState(false);
    const [isShareRaoSpidRequestModalOpen, setIsShareRaoSpidRequestModalOpen] = useState(false);
    const [isRefreshSpidRequestModalOpen, setIsRefreshSpidRequestModalOpen] = useState(false);
    const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = useState(false);
    const [shareSpidRequestModalValues, setShareSpidRequestModalValues] = useState({
        name: "",
        surname: "",
        sessionLink: ""
    });

    const deleteSpidRequestStatus = useSelector(state => state.deleteSpidRequest.status);
    const getSpidListStatus = useSelector(state => state.getSpidList.status);
    const initSpidStatus = useSelector(state => state.initSpid.status);

    const refreshSessionStatus = useSelector(state => state.refreshSession.status);
    const spids = useSelector(state => state.getSpidList.content);
    const spidSlots = useSelector(state => state.getSpidSlots.spidSlots);

    const callWrapper = useCallback(
        (filters, page, sorting) =>
            dispatchWithParentApp(
                getSpidList(
                    page,
                    sorting.sortBy,
                    sorting.sortDirection,
                    filters.fullNameOrTaxId,
                    filters.email,
                    filters.channels,
                    filters.identityTypes,
                    filters.requestedAt,
                    filters.statuses
                )
            ),
        [dispatchWithParentApp]
    );

    const { currentFilters, currentPage, currentSorting, pages, refresh, setPage, updateFilters, updateSorting } =
        useCachedPaginatedCall(callWrapper, spids.page, {
            sortBy: "requestedAt",
            sortDirection: "DESC"
        });

    const onChangeFilters = (data, value) => {
        updateFilters({
            [data]: value
        });
        setPage(0);
    };

    const onChange = (data, value) => {
        updateSorting({
            sortBy: data,
            sortDirection: value
        });
        setPage(0);
    };

    const changeSort = data => {
        if (data === currentSorting.sortBy) {
            switch (currentSorting.sortDirection) {
                case SORT_DIRECTIONS.Ascending:
                    return onChange(data, SORT_DIRECTIONS.Descending);
                case SORT_DIRECTIONS.Descending:
                    return onChange(null, null);
                case null:
                    return onChange(data, SORT_DIRECTIONS.Ascending);
                default:
                    return onChange(null, null);
            }
        } else {
            return onChange(data, SORT_DIRECTIONS.Ascending);
        }
    };

    const handleDetailsDrawer = () => {
        setIsDetailsDrawerOpen(!isDetailsDrawerOpen);
    };

    useEffect(() => {
        dispatchWithParentApp(getSpidSlots());
    }, [dispatchWithParentApp]);

    useEffect(() => {
        if (initSpidStatus.ended) {
            dispatchWithParentApp(getSpidList(currentPage, currentSorting.sortBy, currentSorting.sortDirection));
            dispatchWithParentApp(getSpidSlots());
            setIsModifySpidRequestDrawerOpen(false);
            dispatchWithParentApp(resetInitSpidStatus());
        }
    }, [currentPage, currentSorting, dispatchWithParentApp, initSpidStatus]);

    useEffect(() => {
        if (refreshSessionStatus.ended) {
            dispatchWithParentApp(getSpidList(currentPage, currentSorting.sortBy, currentSorting.sortDirection));
            dispatchWithParentApp(getSpidSlots());
            setIsRefreshSpidRequestModalOpen(false);
            dispatchWithParentApp(resetRefreshSessionStatus());
        }
    }, [currentPage, currentSorting, dispatchWithParentApp, refreshSessionStatus]);

    useEffect(() => {
        if (deleteSpidRequestStatus.ended) {
            dispatchWithParentApp(getSpidList(currentPage, currentSorting.sortBy, currentSorting.sortDirection));
            dispatchWithParentApp(getSpidSlots());
            setIsDeleteSpidRequestModalOpen(false);
            dispatchWithParentApp(resetDeleteSpidRequestStatus());
        }
    }, [currentPage, currentSorting, dispatchWithParentApp, deleteSpidRequestStatus]);

    useEffect(() => {
        deleteSpidRequestStatus.ended &&
            addToast(
                intl.formatMessage({
                    id: "v-spid-list.delete-spid-request.success"
                }),
                "check"
            );
        deleteSpidRequestStatus.error &&
            addToast(
                intl.formatMessage({
                    id: "v-spid-list.delete-spid-request.error"
                }),
                "error"
            );
    }, [addToast, intl, deleteSpidRequestStatus]);

    useEffect(() => {
        refreshSessionStatus.ended &&
            addToast(
                intl.formatMessage(
                    {
                        id: "v-spid-list.refresh-session.success"
                    },
                    {
                        b: chunks => <b>{chunks}</b>,
                        name: selectedSpidRequestToRefresh.name,
                        surname: selectedSpidRequestToRefresh.surname
                    }
                ),
                "check"
            );
        refreshSessionStatus.error &&
            addToast(
                intl.formatMessage({
                    id: "v-spid-list.refresh-session.error"
                }),
                "error"
            );
    }, [addToast, intl, refreshSessionStatus, selectedSpidRequestToRefresh]);

    const videoEnabled = spidSlots?.SPID_VIDEO.totalSlots > 0;
    const slotsRetrieved = spidSlots?.SPID_VIDEO && spidSlots?.SPID;

    return (
        <Container>
            <PageTitle
                title={intl.formatMessage({ id: "v-spid-list.title" })}
                subtitle={intl.formatMessage({ id: "v-spid-list.subtitle" })}
            />
            <ContentWrapper>
                <InfoContainer videoEnabled={videoEnabled}>
                    <InfoBox>
                        <H3 color="darkImperialBlue">
                            <FormattedMessage
                                id="v-spid-list.chart-box.title"
                                values={{
                                    b: chunks => <b>{chunks}</b>
                                }}
                            />
                        </H3>
                        <SpidConsumingChart spidSlots={spidSlots} />
                    </InfoBox>
                    {videoEnabled && (
                        <InfoBox>
                            <H3 color="darkImperialBlue">
                                <FormattedMessage
                                    id="v-spid-list.video-chart-box.title"
                                    values={{
                                        b: chunks => <b>{chunks}</b>
                                    }}
                                />
                            </H3>
                            <VideoConsumingChart spidSlots={spidSlots} />
                        </InfoBox>
                    )}
                    <FaqInfoBoxWrapper videoEnabled={videoEnabled}>
                        <InfoBox backgroundImage={human} videoEnabled={videoEnabled}>
                            <H3 color="darkImperialBlue">
                                <FormattedMessage
                                    id="v-spid-list.faq-box.title"
                                    values={{
                                        b: chunks => <b>{chunks}</b>
                                    }}
                                />
                            </H3>
                            <FaqContainer videoEnabled={videoEnabled}>
                                <StyledA
                                    href="https://agyo.elevio.help/articles/836"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <BodyCopy>
                                        <FormattedMessage id="v-spid-list.faq-list.what" />
                                    </BodyCopy>
                                </StyledA>
                                <StyledA
                                    href="https://agyo.elevio.help/articles/837"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <BodyCopy>
                                        <FormattedMessage id="v-spid-list.faq-list.recognition" />
                                    </BodyCopy>
                                </StyledA>
                                <StyledA
                                    href="https://agyo.elevio.help/articles/838"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <BodyCopy>
                                        <FormattedMessage id="v-spid-list.faq-list.how" />
                                    </BodyCopy>
                                </StyledA>
                            </FaqContainer>
                        </InfoBox>
                    </FaqInfoBoxWrapper>
                </InfoContainer>
                <TableContainer>
                    <TableSectionTitleContainer>
                        <H3 color="darkImperialBlue">
                            <FormattedMessage id="v-spid-list.spid-requests" />
                        </H3>
                        <TableActionsContainer>
                            <Button
                                kind="secondary"
                                onClick={() => {
                                    refresh();
                                    dispatchWithParentApp(getSpidSlots());
                                }}
                            >
                                <FontAwesomeIcon icon={faSyncAlt} />
                            </Button>
                            <Button onClick={() => setIsCreateNewSpidModalOpen(true)} disabled={!slotsRetrieved}>
                                <FormattedMessage id="v-spid-list.create-new-spid-request" />
                            </Button>
                        </TableActionsContainer>
                    </TableSectionTitleContainer>
                    <SpidListTable
                        currentPage={currentPage}
                        filters={currentFilters}
                        getSpidListStatus={getSpidListStatus}
                        handleChangeSort={changeSort}
                        onChangeFilters={onChangeFilters}
                        onChangePage={setPage}
                        onNextPage={() => setPage(currentPage + 1)}
                        onPreviousPage={() => setPage(currentPage - 1)}
                        setIsDeleteSpidRequestModalOpen={setIsDeleteSpidRequestModalOpen}
                        setIsModifySpidRequestDrawerOpen={setIsModifySpidRequestDrawerOpen}
                        setIsRefreshSpidRequestModalOpen={setIsRefreshSpidRequestModalOpen}
                        setIsShareRaoSpidRequestModalOpen={setIsShareRaoSpidRequestModalOpen}
                        setIsShareSpidRequestModalOpen={setIsShareSpidRequestModalOpen}
                        setSelectedSpidRequestToDelete={setSelectedSpidRequestToDelete}
                        setSelectedSpidRequestToEdit={setSelectedSpidRequestToEdit}
                        setSelectedSpidRequestToSeeDetails={setSelectedSpidRequestToSeeDetails}
                        setSelectedSpidRequestToRefresh={setSelectedSpidRequestToRefresh}
                        setShareSpidRequestModalValues={setShareSpidRequestModalValues}
                        setIsDetailsDrawerOpen={setIsDetailsDrawerOpen}
                        sorting={currentSorting}
                        spids={pages[currentPage] || {}}
                        totalPages={spids.totalPages}
                    />
                </TableContainer>
                <SmallDevicesInfoBoxWrapper backgroundImage={human} videoEnabled={videoEnabled}>
                    <H3 color="darkImperialBlue">
                        <FormattedMessage
                            id="v-spid-list.faq-box.title"
                            values={{
                                b: chunks => <b>{chunks}</b>
                            }}
                        />
                    </H3>
                    <FaqContainer videoEnabled={videoEnabled}>
                        <StyledA href="https://agyo.elevio.help/articles/836" target="_blank" rel="noopener noreferrer">
                            <BodyCopy>
                                <FormattedMessage id="v-spid-list.faq-list.what" />
                            </BodyCopy>
                        </StyledA>
                        <StyledA href="https://agyo.elevio.help/articles/837" target="_blank" rel="noopener noreferrer">
                            <BodyCopy>
                                <FormattedMessage id="v-spid-list.faq-list.recognition" />
                            </BodyCopy>
                        </StyledA>
                        <StyledA href="https://agyo.elevio.help/articles/838" target="_blank" rel="noopener noreferrer">
                            <BodyCopy>
                                <FormattedMessage id="v-spid-list.faq-list.how" />
                            </BodyCopy>
                        </StyledA>
                    </FaqContainer>
                </SmallDevicesInfoBoxWrapper>
            </ContentWrapper>
            {isCreateNewSpidModalOpen && slotsRetrieved ? (
                <Modal
                    width={"1024px"}
                    title={intl.formatMessage({ id: "v-spid-list.new-request-modal-title" })}
                    content={
                        slotsRetrieved ? (
                            <CreateNewSpidModalContent
                                setIsModalOpen={setIsCreateNewSpidModalOpen}
                                spidSlots={spidSlots}
                            />
                        ) : null
                    }
                    onHide={() => setIsCreateNewSpidModalOpen(false)}
                />
            ) : null}
            {isShareSpidRequestModalOpen ? (
                <Modal
                    title={intl.formatMessage({ id: "v-spid-list.share-spid-request-modal.title" })}
                    content={
                        <ShareSpidRequestModalContent
                            setIsModalOpen={setIsShareSpidRequestModalOpen}
                            shareSpidRequestModalValues={shareSpidRequestModalValues}
                        />
                    }
                    onHide={() => setIsShareSpidRequestModalOpen(false)}
                    width="755px"
                />
            ) : null}
            {isRefreshSpidRequestModalOpen ? (
                <Modal
                    title={intl.formatMessage({ id: "v-spid-list.refresh-spid-request-modal.title" })}
                    content={
                        <RefreshSpidRequestModalContent selectedSpidRequestToRefresh={selectedSpidRequestToRefresh} />
                    }
                    footer={
                        <RefreshSpidRequestButtonContainer>
                            <Button
                                kind="secondary"
                                onClick={() => setIsRefreshSpidRequestModalOpen(false)}
                                disabled={refreshSessionStatus.started}
                            >
                                <FormattedMessage id="general.cancel" />
                            </Button>
                            <Button
                                onClick={() => dispatchWithParentApp(refreshSession(selectedSpidRequestToRefresh.id))}
                                disabled={refreshSessionStatus.started}
                            >
                                {refreshSessionStatus.started ? (
                                    <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
                                ) : (
                                    <FormattedMessage id="general.confirm" />
                                )}
                            </Button>
                        </RefreshSpidRequestButtonContainer>
                    }
                    onHide={() => setIsRefreshSpidRequestModalOpen(false)}
                    width="500px"
                />
            ) : null}
            {isDeleteSpidRequestModalOpen ? (
                <Modal
                    title={intl.formatMessage({ id: "v-spid-list.delete-spid-request-modal-title" })}
                    type="danger"
                    content={
                        <DeleteSpidRequestModalContent
                            setIsDeleteSpidRequestModalOpen={setIsDeleteSpidRequestModalOpen}
                            selectedSpidRequestToDelete={selectedSpidRequestToDelete}
                        />
                    }
                    onHide={() => setIsDeleteSpidRequestModalOpen(false)}
                    footer={
                        <ModalFooterButtonsContainer>
                            <Button
                                kind="secondary"
                                onClick={() => setIsDeleteSpidRequestModalOpen(false)}
                                disabled={deleteSpidRequestStatus.started}
                            >
                                <FormattedMessage id="general.cancel" />
                            </Button>
                            <Button
                                colorScheme={"alert"}
                                onClick={() => dispatchWithParentApp(deleteSpidRequest(selectedSpidRequestToDelete.id))}
                                disabled={deleteSpidRequestStatus.started}
                            >
                                {deleteSpidRequestStatus.started ? (
                                    <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
                                ) : (
                                    <FormattedMessage id="general.confirm" />
                                )}
                            </Button>
                        </ModalFooterButtonsContainer>
                    }
                />
            ) : null}
            <RaoSpidRequestDialog
                email={shareSpidRequestModalValues.email}
                name={shareSpidRequestModalValues.name}
                open={isShareRaoSpidRequestModalOpen}
                onClose={() => setIsShareRaoSpidRequestModalOpen(false)}
                spidRequestId={shareSpidRequestModalValues.id}
                surname={shareSpidRequestModalValues.surname}
            />
            <ModifySpidRequestDrawer
                open={isModifySpidRequestModalOpen}
                onClose={() => setIsModifySpidRequestDrawerOpen(false)}
                formData={selectedSpidRequestToEdit}
            />
            <DetailsDrawer
                onClose={() => {
                    dispatchWithParentApp(resetGetRaoByIdStatus());
                    handleDetailsDrawer();
                }}
                formData={selectedSpidRequestToSeeDetails}
                open={isDetailsDrawerOpen}
                setIsDeleteSpidRequestModalOpen={setIsDeleteSpidRequestModalOpen}
                setIsModifySpidRequestDrawerOpen={setIsModifySpidRequestDrawerOpen}
                setIsShareSpidRequestModalOpen={setIsShareSpidRequestModalOpen}
                setSelectedSpidRequestToDelete={setSelectedSpidRequestToDelete}
                setSelectedSpidRequestToEdit={setSelectedSpidRequestToEdit}
                setShareSpidRequestModalValues={setShareSpidRequestModalValues}
            />
        </Container>
    );
};

export default SpidList;
