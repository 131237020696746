import { useCallback, useEffect, useMemo, useReducer, useState } from "react";

import debounce from "lodash.debounce";

function useCachedPaginatedCall(callWrapper, currentPage = [], initialSorting = { sortBy: "", sortDirection: "" }) {
    const [filters, updateFilters] = useReducer((filters, updatedFilters) => ({ ...filters, ...updatedFilters }), {});
    const [page, setPage] = useState(0);
    const [sorting, updateSorting] = useReducer(
        (sorting, updatedSorting) => ({ ...sorting, ...updatedSorting }),
        initialSorting
    );
    const [pagesCache, updatePagesCache] = useReducer(
        (pages, { type, payload }) => {
            switch (type) {
                case "update":
                    return { ...pages, ...payload };
                case "init":
                case "reset":
                    return {};
                default:
                    return pages;
            }
        },
        {},
        () => ({ type: "init" })
    );

    const [filtersChanged, setFiltersChanged] = useState(false);
    useEffect(() => {
        setFiltersChanged(true);
    }, [filters]);

    const debouncedCallWrapper = useMemo(() => debounce(callWrapper, 400), [callWrapper]);

    const [loadedPage, setLoadedPage] = useState(0);
    useEffect(() => {
        if (!pagesCache[page]) {
            if (filtersChanged) {
                debouncedCallWrapper(filters, page, sorting);
                setFiltersChanged(false);
            } else {
                callWrapper(filters, page, sorting);
            }
            setLoadedPage(page);
        }
    }, [callWrapper, debouncedCallWrapper, filters, filtersChanged, page, pagesCache, sorting]);

    useEffect(() => {
        if (JSON.stringify(pagesCache[page]) !== JSON.stringify(currentPage) && loadedPage === page) {
            updatePagesCache({ type: "update", payload: { [page]: currentPage } });
        }
    }, [currentPage, loadedPage, page, pagesCache]);

    useEffect(() => {
        updatePagesCache({ type: "reset" });
    }, [filters, sorting]);

    const refresh = useCallback(() => callWrapper(filters, page, sorting), [callWrapper, filters, page, sorting]);

    return {
        currentFilters: filters,
        currentPage: page,
        currentSorting: sorting,
        pages: pagesCache,
        refresh,
        setPage,
        updateFilters,
        updateSorting
    };
}

export default useCachedPaginatedCall;
