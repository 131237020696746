import styled, { css } from "styled-components";

export const ListContainer = styled.ol`
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    margin-bottom: 1.5rem;
`;

export const StepLi = styled.li`
    position: relative;

    ${props =>
        !props.isLast &&
        css`
            &:after {
                content: "";
                display: block;
                height: 2px;
                position: absolute;
                width: 100%;
                right: -50%;
                top: 1rem;
                background-color: ${props =>
                    props.index < props.currentStep
                        ? props.theme.colors.primary.richElectricBlue
                        : props.theme.colors.primary.columbiaBlue};
            }
        `}
`;

export const Step = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const Number = styled.div`
    height: 2rem;
    width: 2rem;
    border-radius: 100%;
    background-color: white;
    border: 2px solid ${props => props.theme.colors.primary.columbiaBlue};
    color: ${props => props.theme.colors.primary.columbiaBlue};
    font-size: 1rem;
    font-family: Cairo;
    font-weight: bold;
    letter-spacing: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    ${props =>
        props.index < props.currentStep &&
        css`
            color: ${props => props.theme.colors.background.white};
            border: none;
            background-color: ${props => props.theme.colors.primary.richElectricBlue};
        `}

    ${props =>
        props.index === props.currentStep &&
        css`
            color: ${props => props.theme.colors.background.white};
            border: none;
            text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.24);
            background: linear-gradient(
                -26.56505117707799deg,
                ${props => props.theme.colors.primary.cyanProgress} 0%,
                ${props => props.theme.colors.primary.richElectricBlue} 100%
            );
        `}
`;

export const Label = styled.div`
    color: ${props => props.theme.colors.primary.darkImperialBlue};
    font-size: 0.875rem;
    font-family: Cairo;
    font-weight: 600;
    line-height: 24px;
`;
