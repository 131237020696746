const it = {
    //*--- GENERAL ---*

    "general.user": "Utente",
    "general.email": "Mail",
    "general.spid-type": "Tipologia SPID",
    "general.creation-date": "Data di creazione",
    "general.request-status": "Stato della richiesta",
    "general.loading": " Caricamento in corso...",
    "general.cancel": "Annulla",
    "general.confirm": "Conferma",
    "general.back": "Indietro",
    "general.continue": "Prosegui",
    "general.close": "Chiudi",
    "general.field-required": "Campo obbligatorio",
    "general.copy": "Copia",
    "general.buy-package": "Acquista pacchetto",
    "general.go-to-store": "Vai sullo Store",
    "general.recognition-method": "Metodo di riconoscimento",
    "general.used": "Utilizzati",
    "general.unlimited": "illimitati",
    "general.retry": "Riprova",
    "general.finished": "Terminate",
    "general.search": "Cerca",
    "general.results-found": "Risultati trovati",
    "general.update": "Aggiorna",
    "general.modify": "Modifica",
    "general.detail": "Dettaglio",
    "general.share": "Condividi",
    "general.delete": "Elimina",
    "general.at": "presso",
    "general.typology": "Tipologia",

    //*--- SPID TYPE ---*

    "spid-type.INDIVIDUAL": "SPID per il privato",
    "spid-type.LEGAL_ENTITY": "LEGAL_ENTITY",
    "spid-type.PROFESSIONAL_INDIVIDUAL": "SPID per il professionista",
    "spid-type.PROFESSIONAL_LEGAL_ENTITY": "PROFESSIONAL_LEGAL_ENTITY",

    //*--- SPID REQUEST STATUS ---*

    "spid-status.APPROVED": "Richiesta in lavorazione",
    "spid-status.CREATED": "Richiesta in lavorazione",
    "spid-status.DELETED": "Richiesta cancellata",
    "spid-status.EXPIRED": "Richiesta emessa",
    "spid-status.HOLD": "Richiesta emessa",
    "spid-status.INITIALIZED": "Richiesta inserita",
    "spid-status.ISSUED": "Richiesta emessa",
    "spid-status.ISSUING": "Richiesta in lavorazione",
    "spid-status.REVOKED": "Richiesta emessa",
    "spid-status.SESSION_EXPIRED": "Richiesta scaduta",

    //*--- SPID RECOGNITION METHODS ---*

    "spid-recognition-method.CIE-CNS-FEQ": "CNS, Firma qualificata",
    "spid-recognition-method.VIDEO": "Video",
    "spid-recognition-method.RAO": "In presenza",

    //*--- TOAST NOTIFICATIONS ---*

    "c-modify-spid-request-modal-content.init-spid.success": "Modifiche salvate correttamente",
    "c-modify-spid-request-modal-content.init-spid.error": "Modifiche non riuscita. Riprovare più tardi",
    "c-share-spid-request-modal-content.send-session-link.success":
        "Condivisione link di richiesta SPID inviata correttamente",
    "c-share-spid-request-modal-content.send-session-link.error":
        "Condivisione link di richiesta SPID non riuscita. Riprovare più tardi",
    "c-modify-spid-request-modal-content.success-toast": "Modifiche salvate correttamente",
    "c-modify-spid-request-modal-content.error-toast": "Modifica richiesta non riuscita. Riprovare più tardi",
    "v-spid-list.delete-spid-request.success": "Richiesta eliminata correttamente",
    "v-spid-list.delete-spid-request.error": "Eliminazione richiesta non riuscita. Riprovare più tardi",
    "v-spid-list.refresh-session.success":
        "<b>Richiesta aggiornata</b> Abbiamo inviato nuovamente la richiesta SPID a {name} {surname}",
    "v-spid-list.refresh-session.error": "Aggiornamento richiesta non riuscita. Riprovare più tardi",
    "c-rao-spid-request-dialog.error": "Condivisione richiesta SPID in presenza non riuscita. Riprovare più tardi",
    "c-rao-spid-request-dialog.success": "Condivisione richiesta SPID in presenza inviata correttamente",

    //*--- VIEWS ---*

    "v-spid-list.title": "TeamSystem ID abilitato SPID",
    "v-spid-list.subtitle": "Gestisci qui la tua richiesta del TSID abilitato SPID",
    "v-spid-list.spid-available-info": "Hai <b>{remainingSlots} SPID disponibili</b> su {totalSlots}",
    "v-spid-list.chart-box.title": "Utilizzo <b>Richieste SPID</b>",
    "v-spid-list.video-chart-box.title": "Utilizzo <b>riconoscimenti video</b>",
    "v-spid-list.faq-box.title": "Tutto sul <b>TS ID abilitato SPID</b>:",
    "v-spid-list.spid-requests": "Le tue richieste SPID",
    "v-spid-list.create-new-spid-request": "Crea nuova richiesta",
    "v-spid-list.new-request-modal-title": "Nuova richiesta SPID",
    "v-spid-list.delete-spid-request-modal-title": "Elimina richiesta SPID",
    "v-spid-list.modify-spid-request-modal-title": "Modifica richiesta",
    "v-spid-list.refresh-spid-request-modal.title": "Aggiorna richiesta",
    "v-spid-list.share-spid-request-modal.title": "Condividi il link di richiesta SPID",
    "v-spid-list.faq-list.what": "Che cosa è il TeamSystem ID abilitato SPID",
    "v-spid-list.faq-list.recognition": "Le modalità di identificazione per richiedere SPID",
    "v-spid-list.faq-list.how": "In che modo utilizzare il TeamSystem ID abilitato SPID",

    //*--- COMPONENTS ---*

    "c-progress-bar.phases": "Fasi completate {completedSteps} di {totalSteps}",

    "c-spid-list-table.empty-spid-request-list": "Nessun richiesta SPID creata",
    "c-spid-list-table.empty-spid-suggestion": "Puoi creare una cliccando su “Crea nuova richiesta”",

    "c-spid-type-selector-box.coming-soon-disclaimer": "Disponibile a breve!",

    "c-new-spid-modal-content.entity.title": "Seleziona il soggetto",
    "c-new-spid-modal-content.entity.description":
        "Per chi vuoi creare questa richiesta SPID? Seleziona <b>privato o professionista</b> se vuoi creare una richiesta SPID per privati, professionisti o ditte individuali, seleziona invece <b>azienda</b> per aziende e/o organizzazioni.",
    "c-new-spid-modal-content.entity.private-or-professional": "Privato o professionista",
    "c-new-spid-modal-content.entity.company": "Azienda",

    "c-new-spid-modal-content.typology.title": "Seleziona il tipo di SPID",
    "c-new-spid-modal-content.typology.description":
        "Che tipologia di SPID vuoi creare? Lo <b>SPID per il privato</b> ti permette di accedere, da privato, ai servizi delle pubbliche amministrazioni, seleziona invece <b>SPID per il professionista</b> per ottenere un'identità che ti qualifica come professionista.",
    "c-new-spid-modal-content.typology.tooltip.finished-slots":
        "Hai esaurito gli SPID inclusi nel tuo pacchetto, acquistane un altro per proseguire con questa scelta.",

    "c-new-spid-modal-content.recognition.title": "Seleziona il metodo di riconoscimento",
    "c-new-spid-modal-content.recognition.description":
        "Seleziona il metodo di riconoscimento che vuoi abilitare per questa richiesta SPID. A seconda del metodo scelto l’utente riceverà una mail con le istruzioni per portare a termine l’attivazione del suo TSID abilitato SPID.",
    "c-new-spid-modal-content.recognition.cieCnsFeqChannels.title":
        "Riconoscimento con Carta Nazionale dei Servizi o Firma qualificata",
    "c-new-spid-modal-content.recognition.cieCnsFeqChannels.complete-time":
        "Tempo indicativo per il completamento: <b>5 minuti</b>",
    "c-new-spid-modal-content.recognition.cieCnsFeqChannels.cost": "Costo: <b>gratuito</b> (inluso nel pacchetto)",
    "c-new-spid-modal-content.recognition.cieCnsFeqChannels.required":
        "Proseguendo con questo metodo, per portare a termine la procedura (totalmente da remoto) il cliente dovrà avere:",
    "c-new-spid-modal-content.recognition.cieCnsFeqChannels.identity-card":
        "Oppure una <b>Carta Nazionale dei Servizi</b> (CNS) valida e un <b>lettore di smart-card</b>",
    "c-new-spid-modal-content.recognition.cieCnsFeqChannels.sign": "Una <b>firma qualificata</b> attiva",
    "c-new-spid-modal-content.recognition.default.title": "Scegli il metodo di riconoscimento",
    "c-new-spid-modal-content.recognition.default.description":
        "Scegli uno dei metodi disponibili, verifica tempi e disponibilità e procedi con la creazione della richiesta TSID abilitato SPID.",
    "c-new-spid-modal-content.recognition.video.title": "Riconoscimento con Video",
    "c-new-spid-modal-content.recognition.video.buy-video.title": "Riconoscimento video",
    "c-new-spid-modal-content.recognition.video.buy-video.description":
        "Il riconoscimento video è una funzionalità a pagamento. Attualmente non hai dei piani attivi per poter consentire questo metodo alle tue richieste SPID.",
    "c-new-spid-modal-content.recognition.video.time-for-completion":
        "Tempo indicativo per il completamento: <b>10 minuti</b>",
    "c-new-spid-modal-content.recognition.video.cost": "Costo: <b>1 RICONOSCIMENTO VIDEO</b> (Ne hai:",
    "c-new-spid-modal-content.recognition.video.out-of-stock.title": "Riconoscimenti video esauriti",
    "c-new-spid-modal-content.recognition.video.out-of-stock.buy":
        "Acquista un nuovo pacchetto o effettua un upgrade per poter proseguire con questo metodo",
    "c-new-spid-modal-content.recognition.video.info.description":
        "Proseguendo con questo metodo, il cliente verrà guidato da un nostro operatore sulla piattaforma dedicata. Per portare a termine la procedura, saranno necessari:",
    "c-new-spid-modal-content.recognition.video.info.phone-number-and-email": "Numero di cellulare e casella mail",
    "c-new-spid-modal-content.recognition.video.info.identity-card":
        "<b>Documento di identità</b> (patente, carta di identità o passaporto)",
    "c-new-spid-modal-content.recognition.video.info.health-insurance-card": "Tessera sanitaria",
    "c-new-spid-modal-content.recognition.video.info.connection-and-webcam":
        "<b>Dispositivo collegato a Internet con webcame e microfono funzionanti</b> (PC, smartphone o tablet)",
    "c-new-spid-modal-content.recognition.rao.title": "Riconoscimento in presenza",
    "c-new-spid-modal-content.recognition.rao.complete-time": "Tempo indicativo per il completamento: <b>5 minuti</b>",
    "c-new-spid-modal-content.recognition.rao.cost": "Costo: <b>gratuito</b> (inluso nel pacchetto)",
    "c-new-spid-modal-content.recognition.rao.description":
        "Proseguendo con questo metodo, potrai scegliere, tra una lista di RAO dove il cliente dovrà recarsi per effettuare il riconoscimento di persona. Per portare a termine la procedura, saranno necessari:",
    "c-new-spid-modal-content.recognition.rao.info.phone-email": "Numero di cellulare e casella email",
    "c-new-spid-modal-content.recognition.rao.info.identity-card":
        "<b>Documento di identità</b> (patente, carta di identità o passaporto)",
    "c-new-spid-modal-content.recognition.rao.info.health-insurance-card": "Tessera sanitaria",
    "c-new-spid-modal-content.recognition.rao-selection.title": "Scegli dove permettere il riconoscimento di persona",
    "c-new-spid-modal-content.recognition.rao-selection.description": "Inserisci località, CAP o Nome dello studio",
    "c-new-spid-modal-content.owner-data.title": "Inserisci i dati",
    "c-new-spid-modal-content.owner-data.description":
        "Seleziona i dati del destinatario di questa richiesta SPID.{br}{br}Tutti i campi sono obbligatori.",
    "c-new-spid-modal-content.owner-data.field.name.label": "Nome",
    "c-new-spid-modal-content.owner-data.field.name.placeholder": "Inserisci qui il nome",
    "c-new-spid-modal-content.owner-data.field.surname.label": "Cognome",
    "c-new-spid-modal-content.owner-data.field.surname.placeholder": "Inserisci qui il cognome",
    "c-new-spid-modal-content.owner-data.field.taxid.label": "Codice fiscale",
    "c-new-spid-modal-content.owner-data.field.taxid.placeholder": "Inserisci qui il codice fiscale",
    "c-new-spid-modal-content.owner-data.field.email.label": "Email",
    "c-new-spid-modal-content.owner-data.field.email.placeholder": `Ad esempio "nome@gmail.com"`,
    "c-new-spid-modal-content.owner-data.field.email.disclamer":
        "Attenzione: La mail deve essere del destinatario della richiesta, servirà per accedere alla procedura di attivazione.",

    "c-new-spid-modal-content.feedback.loading.title": "Richiesta SPID in elaborazione...",
    "c-new-spid-modal-content.feedback.loading.description": "Stiamo inviando la tua richiesta",
    "c-new-spid-modal-content.feedback.error.title": "Richiesta SPID non riuscita",
    "c-new-spid-modal-content.feedback.error.description": "Stiamo inviando la tua richiesta",
    "c-new-spid-modal-content.feedback.success.title": "Richiesta SPID creata con successo!",
    "c-new-spid-modal-content.feedback.success.description":
        "Abbiamo inviato una mail a <b>{name} {surname}</b> all'indirizzo <b>{email}</b> con le indicazioni per portare a termine la creazione del TSiD abilitato SPID. Potrai vedere lo stato della richiesta nella tua dashboard.",

    "c-new-spid-modal-content.rao-selection.search-rao": "Effettua la ricerca",
    "c-new-spid-modal-content.rao-selection.search-rao.description":
        "Usa i filtri a lato per cercare il soggetto incaricato alla verifica dell'indennità (RAO). Puoi cercare per nome, per provincia o poi città",
    "c-new-spid-modal-content.rao-selection.empty-list.title": "Nessun risultato trovato",
    "c-new-spid-modal-content.rao-selection.empty-list.description":
        "Controlla di aver digitato correttamente il nome, la provincia o la città",
    "c-new-spid-modal-content.rao-selection.insert-name": "Inserisci il nome dello studio",
    "c-new-spid-modal-content.rao-selection.insert-name.placeholder": `Ad esempio "Studio Rossi"`,
    "c-new-spid-modal-content.rao-selection.select-province": "Seleziona la provincia",
    "c-new-spid-modal-content.rao-selection.select-province.placeholder": "Digita o seleziona la provincia",
    "c-new-spid-modal-content.rao-selection.select-city": "Seleziona la città",
    "c-new-spid-modal-content.rao-selection.select-city.placeholder": "Digita o seleziona la città",

    "c-share-spid-request-modal-content.session-link-input.label": "Link di condivisione di {name} {surname}:",
    "c-share-spid-request-modal-content.other-sharing-options": "Altre opzioni di condivisione",

    "c-rao-spid-request-dialog.title": "Condividi richiesta SPID",
    "c-rao-spid-request-dialog.description":
        "Vuoi condividere nuovamente la richiesta SPID con <b>{name} {surname}</b>? Se prosegui invieremo all’indirizzo <b>{email}</b> il reminder delle informazioni sul luogo dove deve recarsi per effettuare il riconoscimento (RAO) e portare a termine la procedura SPID.",

    "c-spid-consuming-chart.used": "Richiesti",
    "c-spid-consuming-chart.available": "Disponibili",
    "c-spid-consuming-chart.extra-usage": "Extrasoglia",
    "c-spid-consuming-chart.slot-description": "Richieste SPID utilizzate",

    "c-delete-spid-request-modal-content.body": "Vuoi eliminare la richiesta SPID per <b>{name} {surname}</b>?",

    "c-refresh-spid-request-modal-content.update-request-for-another-3-days":
        "Vuoi aggiornare la richiesta SPID per <b>{name} {surname}</b> per altri 3 giorni?",
    "c-refresh-spid-request-modal-content.send-new-link-to":
        "Invieremo gratuitamente il nuovo link per la creazione del TSID abilitato SPID all’indirizzo <b>{email}</b> e sarà valido fino al {date}",

    "c-video-consuming-chart.used-video-slot": "Riconoscimenti video utilizzati",

    "c-details-drawer.title": "Dettaglio della richiesta",
    "c-details-drawer.recipient-data": "Dati del destinatario",
    "c-details-drawer.request-date": "Data della richiesta",
    "c-details-drawer.request-status": "Stato richiesta"
};

export default it;
